<template>
  <div class="box" @click="toHome()">
    <img src="../assets/home/err404.png" alt="" />
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  data() {
    return {}
  },
  methods: {
    toHome() {
      this.$router.go(-1) //返回上一页面
    },
  },
}
</script>
<style scoped>
.box > img {
  height: 96vh;
  width: 100vw;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">产品-硬件</div>
      </div>
    </div>
    <!-- 中心内容 -->
    <div class="content-center-box">
      <div class="content-tab">
        <p class="tab-title" :class="{ 'tab-active': classId == 0 }" @click="goSelfHelp()">心理自助服务系统</p>
        <p class="tab-title" :class="{ 'tab-active': classId == 1 }" @click="landscapeEvent()">横屏使用说明</p>
        <P class="tab-title" :class="{ 'tab-active': classId == 2 }" @click="verticalEvent()">竖屏使用说明</P>
      </div>
      <div class="content-center" v-if="classId == 0">
        <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/yingjian%403x.png" alt="">
      </div>
      <div class="content-center" v-else>
        <div v-if="classId == 2">
          <object id="pdf" type="application/pdf" data="/心灵通-心理自助系统使用说明书（竖屏）.pdf"> </object>
        </div>
        <div v-if="classId == 1">
          <object id="pdf" type="application/pdf" data="/心灵通-心理自助系统使用说明书（横屏）.pdf"> </object>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      classId: 0,
    }
  },
  mounted() {
    // let classId = this.$route.query.classId;
    // this.classId = classId
    // if (classId = 1) {
    //   this.classId = 1
    // } else if (classId = 2) {
    //   this.classId = 2
    // }
    // else {
    //   this.classId = 0
    // }
  },
  methods: {
    landscapeEvent() {  //横版说明
      this.classId = 1
    },

    verticalEvent() {   //竖版说明
      this.classId = 2
    },

    goSelfHelp() {
      this.classId = 0
    },

  }
};
</script>
  
<style lang="less" scoped>
@import '../css/global.css';

.box {
  background: #EFF4FF;
}

// 中心内容
.topImg-title {
  line-height: 108px;
}

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.content-center-box {
  padding-bottom: 150px;
}

.content-center {
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 60px;
  min-height: 600px;
}


.content-center>img {
  width: 100%;
  padding-top: 74px;
}

.content-tab {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-top: 22px;
}

.tab-title {
  font-size: 16px;
  margin-right: 32px;
  cursor: pointer;
}

.tab-active {
  color: #0156ff;
}

.tab-title:hover {
  color: #0156ff;
}

#pdf {
  width: 1280px;
  height: 1190px;
}
</style>
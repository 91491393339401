<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img-one.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">方案-教师培训</div>
      </div>
    </div>
    <div class="center">
      <div class="center-one">
          <div class="center-one-img">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/train-one.png" alt="" />
          </div>
          <div class="center-one-text">
            <div class="leftIcon"><img src="../assets/scheme/b.png" alt=""></div>
            <p>
              根据高等教育、中等职业教育和基础教育的特点，针对管理部门、咨询师、课程授课教师、辅导员等角色提供专业的培训课程体系，激活教师团队，
              提升本土化的心理危机识别与干预能力，培训分为线上培训和线下培训两种模式，支持为学校定制个性化培训方案。
            </p>
            <div class="rightIcon"><img src="../assets/scheme/a.png" alt=""></div>
          </div>
      </div>
      <div class="center-two">
        <div class="center-four-img">
          <div class="alignItemsCenter">
            <div class="center-four-third-left">
              <div style="display: flex; margin-top: 40px; margin-left: 55px">
                <div class="alignItemsCenter left-box">
                  <img src="../assets/scheme/four-third-one.png" alt="" />
                  <div class="center-four-third-left-title">基本素养培训</div>
                </div>
                <div>
                  <ul>
                    <li>心理危机筛查与干预相关政策</li>
                    <li>基础教育常见心理问题及处理办法</li>
                    <li>心理危机筛查和干预过程中的注意事项</li>
                  </ul>
                </div>
              </div>
              <div style="display: flex; margin-top: 40px; margin-left: 55px">
                <div class="alignItemsCenter left-box">
                  <img src="../assets/scheme/four-third-two.png" alt="" />
                  <div class="center-four-third-left-title">
                    风险筛选技能培养
                  </div>
                </div>
                <div>
                  <ul>
                    <li>基础教育心理危机筛查与干预主要流程和工作内容</li>
                    <li>学生一对一访谈和风险识别培训</li>
                    <li>心理咨询常见问题及方法</li>
                  </ul>
                </div>
              </div>
              <div style="display: flex; margin-top: 40px; margin-left: 55px">
                <div class="alignItemsCenter left-box">
                  <img src="../assets/scheme/four-third-three.png" alt="" />
                  <div class="center-four-third-left-title">管理和技术培训</div>
                </div>
                <div>
                  <ul>
                    <li>心理危机筛查与干预整体流程培训</li>
                    <li>心理危机筛查与干预管理员培训</li>
                    <li>心理测评培训</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="center-four-third-right">
              <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/center-four-third-right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import "../css/global.css";
.topImg-title{
  font-size: 36px;
  font-weight: bold;
}
.center {
  width: 1200px;
  margin: 0 auto;
}
.topImg-headline {
    width: 1200px;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}
.center-one {
  margin-top: 74px;
  align-items: center;
  width: 596px;
  height: 360px;
  position: relative;
}
.center-one-img > img {
  width: 596px;
  height: 360px;
}
.center-one-text {
    width: 775px;
    height: 306px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 11px 0px rgb(0 0 0 / 10%);
    margin-left: 423px;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px 57px 56px 57px;
    box-sizing: border-box;
}
.center-one-text > p {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 28px;
  letter-spacing: 1px;
  padding: 24px 0px;
  text-align: justify;
}
.center-two {
  margin-top: 74px;
  margin-bottom: 150px;
  display: flex;
}
.alignItemsCenter {
  display: flex;
  align-items: center;
  width: 280px;
}
.center-four-img {
  margin-bottom: 74px;
  position: relative;
  z-index: 1;
}
.zhongdengBj {
  position: absolute;
  bottom: -4px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 0;
  width: 1920px;
}
.zhongdengBj img {
  width: 100%;
}
.center-four-third-left {
  width: 752px;
  height: 448px;
  background: #ffffff;
  box-shadow: 6px 7px 13px 0px rgba(1, 86, 255, 0.09);
}
.center-four-third-left-title {
  margin-left: 16px;
  margin-right: 98px;
  white-space: nowrap;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  letter-spacing: 3px;
}
ul > li {
  width: 400px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 32px;
  letter-spacing: 2px;
  list-style: inside;
}
ul li::marker {
  color: #0156ff;
}
.center-four-third-right > img {
  width: 450px;
}
.center-four-fourth {
  width: 282px;
  height: 303px;
  background: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(1, 86, 255, 0.1);
  border-radius: 10px;
}
.center-four-fourth-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  letter-spacing: 3px;
  margin-bottom: 24px;
  text-align: center;
}
.center-four-fourth-text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
}
.leftIcon>img,.rightIcon>img{
  width: 20px;
  
}
.rightIcon{
  text-align: right;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img-one.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">大学生</div>
        <div class="topImg-text">心理健康教育与咨询整体解决方案</div>
      </div>
    </div>
    <!-- one -->
    <div class="center-one">
      <div class="center-one-content">
        <h1>大学生心理健康工作常见问题</h1>
        <div class="center-one-content-img">
          <img src="../assets/scheme/schemeHeight-one.png" alt="" />
        </div>
      </div>
    </div>
    <!-- two -->
    <div class="content-two">
      <div class="center-two-content">
        <h1>高等教育心理健康解决方案</h1>
        <div class="center-two-contentImg">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/schemeHeight-two.png" alt="" />
        </div>
      </div>
    </div>
    <!-- three -->
    <div class="content-three">
      <el-tabs v-model="activeName">
        <el-tab-pane label="平台" name="first">
          <h3>平台系统框架图</h3>
          <div class="elTabPlane">
            <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/schemeHigher-first.png" alt="" />
          </div>
          <div class="content-four">
            <div class="elTabPlane">
              <h1>核心应用场景</h1>
              <div class="changjing-box">
                <div class="changjing">
                  <div class="changjingImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/changjing-one.png" alt="" />
                  </div>
                  <p class="changjing-title">危机干预</p>
                  <p class="changjing-text">从测评、咨询、日常和访谈多维度识别危机，通过信息化实现危机提交、危机确认、危机处理的全流程闭环管理和精准干预</p>
                </div>
                <div class="changjing">
                  <div class="changjingImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/changjing-two.png" alt="" />
                  </div>
                  <p class="changjing-title">课程学习</p>
                  <p class="changjing-text">整合优质课程，提供全场景教学功能，完整记录线上线下教学全过程数据，服务必修课和选修课日常教学及教改工作</p>
                </div>
                <div class="changjing">
                  <div class="changjingImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/changjing-three.png" alt="" />
                  </div>
                  <p class="changjing-title">心理普查</p>
                  <p class="changjing-text">校内开展大规模心理普查，系统提供丰富的量表库，支持个性化量表，对普测结果进行分析，自动生成普测报告</p>
                </div>
                <div class="changjing">
                  <div class="changjingImg">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/changjing-four.png" alt="" />
                  </div>
                  <p class="changjing-title">心理咨询</p>
                  <p class="changjing-text">提供咨询预约、线下咨询管理、线上专家咨询和自助咨询等多元化服务，帮助学校提高咨询指导服务的效率和质量</p>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="内容" name="second">
          <h3>大学生心理健康课程资源</h3>
          <div style="display: flex; justify-content: space-between" class="elTabPlane">
            <div class="content-class">
              <div>
                <img src="../assets/scheme/content-one.png" alt="" />
              </div>
              <p class="content-class-title">教师：李斌</p>
              <p class="content-class-text">单位：长沙民政职业技术学院</p>
            </div>
            <div class="content-class">
              <div>
                <img src="../assets/scheme/content-two.png" alt="" />
              </div>
              <p class="content-class-title">教师：施钢</p>
              <p class="content-class-text">单位：中国农业大学</p>
            </div>
            <div class="content-class">
              <div>
                <img src="../assets/scheme/content-three.png" alt="" />
              </div>
              <p class="content-class-title">教师：李媛</p>
              <p class="content-class-text">单位：电子科技大学</p>
            </div>
          </div>
          <div class="selfClass">
            <h3 class="selfClass-title">大学生心理健康自助学习资源</h3>
            <div class="selfClass-content">
              <div class="selfClass-content-left">
                <div>
                  <div class="a"><img src="../assets/scheme/selfClass-one.png" alt="" /></div>
                  <div class="leftBanner">
                    <div class="block">
                      <el-carousel style="height: 300px, width:482px" arrow="never" indicator-position="none">
                        <el-carousel-item v-for="item in classBanners" style="height: 300px, width:482px" :key="item.url">
                          <img :src="item.url" alt="" />
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </div>
                <p class="selfClass-text">有声读物、图文、期刊类 以视频、音频等为主要形式的心理自助资源</p>
              </div>
              <div class="selfClass-content-right">
                <div>
                  <div><img src="../assets/scheme/selfClass-two.png" alt="" /></div>
                  <div class="rightBanner">
                    <div class="block">
                      <el-carousel style="height: 390px; width: 180px" arrow="never" indicator-position="none">
                        <el-carousel-item v-for="item in classRightBanners" style="height:390px, width:180px" :key="item.url">
                          <img :src="item.url" alt="" />
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>
                </div>
                <p class="selfClass-text">心理测评支持移动端+PC端的形式 方便用户随时随地进行兴趣测评</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="培训" name="third">
          <div class="elTabPlane">
            <h3>个性化师资培训方案</h3>
            <div class="peixun-box">
              <div class="peixun-left">
                <div class="peixun-left-img-bj"></div>
                <div class="peixun-left-img-bj-img">
                  <img src="../assets/scheme/peixun-one.png" alt="" />
                </div>
              </div>
              <div class="peixun-right">
                <div>
                  <div class="peixun-title">中心指导</div>
                  <ul>
                    <li>制定工作计划与活动安排</li>
                    <li>实施心理咨询案例常规督导</li>
                    <li>参与学生心理危机事件处理工作</li>
                    <li>心理健康教育教学和心理咨询工作</li>
                  </ul>
                </div>
                <div>
                  <div class="peixun-title">咨询师</div>
                  <ul>
                    <li>伦理守则</li>
                    <li>高校心理咨询流程</li>
                    <li>高校常见心理问题及对策</li>
                    <li>高校心理咨询理论及方法</li>
                  </ul>
                </div>
                <div>
                  <div class="peixun-title">辅导员</div>
                  <ul>
                    <li>突发事件应对方法</li>
                    <li>心理危机干预方式</li>
                    <li>常见心理问题及应对</li>
                    <li>学生访谈流程和方法</li>
                  </ul>
                </div>
                <div>
                  <div class="peixun-title">心理委员</div>
                  <ul>
                    <li>突发事件应对方法</li>
                    <li>重点关注工作内容</li>
                    <li>高校常见心理问题及应对</li>
                    <li>心理健康知识宣传及普及</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="硬件" name="fourth">
          <h3>心理健康教育空间环境建设方案</h3>
          <div>
            <div class="product-bottom elTabPlane">
              <div class="product-bottom-img">
                <div class="product-bottom-img-hover">
                  <h4>团体咨询室</h4>
                  <p class="product-bottom-img-hover-text">可配置设备：团体活动包、游戏、素质拓展包、心理挂图及制度</p>
                  <p class="product-bottom-img-hover-text">该室可用于提升人际交往能力、合作意识和个人优势开发。也可用于开展团体辅导活动，或者用于教师开展教学研讨、教师培训等活动。</p>
                  <router-link to="/productHard" class="hoverSeeMore seeMore">
                    了解更多
                    <div style="margin-left: 10px">
                      <img src="../assets/home/seemore-btn2.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <img src="../assets/home/product-one.png" alt="" />
                <div class="product-bottom-img-text">团体咨询室</div>
              </div>

              <div class="product-bottom-img">
                <div class="product-bottom-img-hover">
                  <h4>情绪宣泄室</h4>
                  <p class="product-bottom-img-hover-text">可配置设备：不倒翁人形沙袋、呐喊宣泄系统、打击宣泄系统等</p>
                  <p class="product-bottom-img-hover-text">该室可用于通过各种道具和宣泄系统的应用，舒缓个人紧张情绪，让人回归平静。</p>
                  <router-link to="/productHard" class="hoverSeeMore seeMore">
                    了解更多
                    <div style="margin-left: 10px">
                      <img src="../assets/home/seemore-btn2.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <img src="../assets/home/product-two.png" alt="" />
                <div class="product-bottom-img-text">情绪宣泄室</div>
              </div>
              <div class="product-bottom-img">
                <div class="product-bottom-img-hover">
                  <h4>音乐放松室</h4>
                  <p class="product-bottom-img-hover-text">可配置设备：音乐治疗自助减压系统软件、心理挂图及制度</p>
                  <p class="product-bottom-img-hover-text">该室可用于音乐放松训练，消除悲观、焦虑紧张等负面情绪，改善睡眠质量。该室亦可用于辅助催眠。</p>
                  <router-link to="/productHard" class="hoverSeeMore seeMore">
                    了解更多
                    <div style="margin-left: 10px">
                      <img src="../assets/home/seemore-btn2.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <img src="../assets/home/product-three.png" alt="" />
                <div class="product-bottom-img-text">音乐放松室</div>
              </div>
              <div class="product-bottom-img">
                <div class="product-bottom-img-hover">
                  <h4>心理咨询室</h4>
                  <p class="product-bottom-img-hover-text">可配置设备：沙发、茶几、绿植、心理图书、心理挂图及制度</p>
                  <p class="product-bottom-img-hover-text">该室可用于倾吐心声、认识自我、增强来访者的心理适应能力、心理承受能力。</p>
                  <router-link to="/productHard" class="hoverSeeMore seeMore">
                    了解更多
                    <div style="margin-left: 10px">
                      <img src="../assets/home/seemore-btn2.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <img src="../assets/home/product-four.png" alt="" />
                <div class="product-bottom-img-text">心理咨询室</div>
              </div>
              <div class="product-bottom-img">
                <div class="product-bottom-img-hover">
                  <h4>心理训练室</h4>
                  <p class="product-bottom-img-hover-text">可配置设备：桌椅、电脑、沙发、系统软件、心理挂图及制度</p>
                  <p class="product-bottom-img-hover-text">该室可用于情绪控制训练、抗挫折培训、学习压力管理。 可提升幸福感、改善考试状态、缓解考试焦虑、改善人际关系。</p>
                  <router-link to="/productHard" class="hoverSeeMore seeMore">
                    了解更多
                    <div style="margin-left: 10px">
                      <img src="../assets/home/seemore-btn2.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <img src="../assets/home/product-five.png" alt="" />
                <div class="product-bottom-img-text">心理训练室</div>
              </div>
              <div class="product-bottom-img">
                <div class="product-bottom-img-hover">
                  <h4>沙盘游戏室</h4>
                  <p class="product-bottom-img-hover-text">可配置设备：立柜、桌子、沙盘游戏、心理挂图及制度</p>
                  <p class="product-bottom-img-hover-text">该室可通过沙盘游戏的非言语支持，激发自我力量，化解内心冲突与压力。</p>
                  <router-link to="/productHard" class="hoverSeeMore seeMore">
                    了解更多
                    <div style="margin-left: 10px">
                      <img src="../assets/home/seemore-btn2.png" alt="" />
                    </div>
                  </router-link>
                </div>
                <img src="../assets/home/product-six.png" alt="" />
                <div class="product-bottom-img-text">沙盘游戏室</div>
              </div>
            </div>
          </div>
          <div class="jianshe">
            <h3 class="selfClass">预期建设效果</h3>
            <div class="jianshe-box">
              <div style="margin-top: 74px">
                <img src="../assets/scheme/jianshe-one.png" alt="" />
              </div>
              <div class="jianshe-box-shade">
                <div class="jianshe-box-shade-content">
                  <ul>
                    <li class="dian">提供覆盖全校的普测服务，提供深度的普测数据分析</li>
                    <li class="dian">针对普测数据形成智能筛查与精准干预的完整闭环管理机制</li>
                  </ul>
                  <ul>
                    <li class="dian">提供系统化的心理必修课和选修课优质课程，提供针对性活动管理功能</li>
                    <li class="dian">提供多种模式的咨询平台实现心理咨询不打烊，提供专家咨询服务</li>
                  </ul>
                  <ul>
                    <li class="dian">实现普测、课程、活动、咨询等数据统一管理,提供科学全面及时的数据服务</li>
                    <li class="dian">基于画像形成精准施策闭环管理，数据推动个性化指导，推动分类教育</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="xlt">
            <h3>心灵通 “四统一”助力高校建设心理健康新平台</h3>
            <div class="zhongdengBj"><img src="../assets/scheme/gaodeng-Bj.png" alt="" /></div>
            <div class="xlt-box">
              <div class="center-four-img">
                <p class="center-four-second-title">通过应用培训、线下支持、电话答疑、普测报告等方式帮助教育局解决在普测过程中的一系列技术与应用问题。</p>
                <div style="display: flex; justify-content: space-between">
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/gaodengOne.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">网络应用与保障隐私</div>
                      <p class="center-four-fourth-text">严格遵守心理健康教育法律、伦理等要求，不仅通过网络提高效率，同时采用非对称加密模式保障个人隐私</p>
                    </div>
                  </div>
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/gaodengTwo.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">发现问题与解决问题</div>
                      <p class="center-four-fourth-text">改变传统的工作模式，不仅通过测评等系统发现问题，同时也有后续干预与教育解决问题的方案</p>
                    </div>
                  </div>
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/gaodengThree.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">过程管理与源头治理</div>
                      <p class="center-four-fourth-text">一方面强调危机干预的过程管理，同时注重通过教育从源头治理上解决心理健康长效机制问题</p>
                    </div>
                  </div>
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/gaodengFour.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">规范管理与效率提升</div>
                      <p class="center-four-fourth-text">平台应用模式灵活，一方面注重规范管理，降低系统风险，同时注重通过信息化提高效率，减少教师重复性工作</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="display: none">
      <div class="content-five">
        <div class="center-five-content">
          <h1>应用案例</h1>
          <div style="display: flex; justify-content: space-around; width: 1200px; margin: 0 auto">
            <div class="center-five-left">
              <div style="text-align: center; margin-top: 32px">
                <img src="../assets/scheme/shifanSchool.png" alt="" />
              </div>
              <h5 class="center-five-title">河北民族师范学院</h5>
              <p class="center-five-text">针对初筛等级为一级、二级、三级的1194名学生，通过心灵通平台提供的“大学生风险筛查”量表（检出率5‰）进行二次筛查，测评方便、筛查精准。 而且通过小程序访问稳定，很方便学生参与测评，提高了学校心理普查工作效率，提前针对疑似问题学生开展心理辅导工作。</p>
            </div>
            <div class="center-five-right">
              <p class="center-five-right-text">此为测试示意图，不是用户应用截图</p>
              <div class="block">
                <el-carousel height="450px" arrow="never" indicator-position="outside">
                  <el-carousel-item v-for="item in imgs" style="height: 466px, width:282px" :key="item.url">
                    <img :src="item.url" alt="" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      time: '',
      imgs: [{ url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/lunboThree-1.png' }, { url: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/lunboThree-2.png' }],
      banners: [{ url: require('../assets/scheme/lunboThree-1.png') }, { url: require('../assets/scheme/lunboThree-2.png') }],
      classBanners: [{ url: require('../assets/scheme/classBanners-1.png') }, { url: require('../assets/scheme/classBanners-2.png') }],
      classRightBanners: [{ url: require('../assets/scheme/classRightBanners-1.png') }, { url: require('../assets/scheme/classRightBanners-2.png') }],
    }
  },
  methods: {},
  created() {},
}
</script>
<style lang="less" scoped>
@import '../css/global.css';

.topImg-title {
  line-height: 32px;
}
.topImg-headline {
  top: 70px;
}
.topImg-title,
.topImg-text {
  font-weight: bold;
  font-size: 36px;
}
.center-one {
  width: 100%;
  background-color: #ffffff;
}
.center-one-content {
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
}
.center-one-content-img > img {
  padding-bottom: 74px;
}
h1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 6px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 50px;
}
.content-two {
  width: 100%;
  // height: 854px;
  background: #f8faff;
}
.center-two-content {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
}
.center-two-contentImg > img {
  width: 1200px;
}
.content-three {
  width: 100%;
  background: #ffffff;
}

.center-three-content {
  margin: 0 auto;
  width: 1200px;
}
h3 {
  text-align: center;
  margin: 50px 0px 32px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 4px;
}
.content-class {
  width: 384px;
  height: 326px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(1, 86, 255, 0.1);
  border-radius: 10px;
  cursor: pointer;
}
.content-class-title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  // font-weight: bold;
  color: #3b3d41;
  line-height: 25px;
  margin: 20px 24px 14px;
}
.content-class-text {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 20px;
  margin: 0px 24px;
}
.product-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
.product-bottom-img {
  margin-top: 32px;
  width: 384px;
  height: 274px;
  display: flex;
  position: relative;
  cursor: pointer;
}
.product-bottom-img:hover .product-bottom-img-hover {
  display: block;
  background: linear-gradient(to right, #0156ff, #7caefc);
}
h4 {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
  padding: 40px 32px 16px;
}
.product-bottom-img-text {
  position: absolute;
  top: 50%;
  left: 40%;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}
.product-bottom-img-hover {
  width: 384px;
  height: 274px;
  background: #4676f8;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.product-bottom-img-hover-text {
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  padding: 0px 32px 20px;
}
.seeMore {
  float: right;
  height: 22px;
  margin: 0 24px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  position: absolute;
  bottom: 32px;
  right: 0px;
  display: flex;
}
// four
.content-four {
  width: 100%;
  height: 540px;
  background: #f8faff;
  margin-top: 50px;
}
// 核心应用场景
.selfClass {
  width: 100%;
  background: #f8faff;
  padding-top: 60px;
}

.selfClass-title {
  margin-top: 0px;
  margin-bottom: 54px;
}
.changjing-box {
  display: flex;
  justify-content: space-between;
}
.changjing {
  height: 346px;
  width: 282px;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
}
.changjingImg > img {
  width: 282px;
}
.changjing-title {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 2px;
  margin: 14px 0px 18px;
  text-align: center;
  padding: 0px 18px;
}
.changjing-text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
  padding: 0px 18px;
}
// 心理健康自助学习资源
.selfClass-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.selfClass-content-left {
  width: 482px;
  position: relative;
}
.leftBanner {
  z-index: 1;
  position: absolute;
  left: 80px;
  top: -14px;
  width: 480px;
}

.rightBanner {
  z-index: 1;
  position: absolute;
  bottom: 172px;
  left: 66px;
  width: 180px;
  height: 390px;
}
/deep/.el-carousel__container {
  height: 390px;
}

.selfClass-content-right {
  position: relative;
  width: 313px;
  text-align: center;
  margin-top: -20px;
}
.selfClass-text {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
  text-align: center;
  margin: 50px 0 60px;
}

// 个性化师资培训方案
.peixun-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 74px;
  margin-top: 82px;
}
.peixun-title {
  width: 140px;
  height: 32px;
  line-height: 32px;
  color: white;
  text-align: center;
  background: #0156ff;
  border-radius: 25px;
  margin-bottom: 24px;
}
.peixun-right > div:nth-child(3n),
.peixun-right > div:nth-child(4n) {
  margin-top: 50px;
}
.peixun-right {
  display: flex;
  flex-flow: wrap;
  margin-left: 60px;
}
.peixun-right > div {
  width: 300px;
}
.peixun-right ul {
  height: 128px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 32px;
  letter-spacing: 2px;
}
.peixun-left {
  position: relative;
}
.peixun-left-img-bj {
  width: 378px;
  height: 362px;
  background: #0156ff;
  position: absolute;
  left: -40px;
  top: -40px;
  background: linear-gradient(180deg, #0357ff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 0;
}
.peixun-left-img-bj-img {
  position: relative;
  z-index: 1;
}
.zhongdengBj {
  position: absolute;
  bottom: -4px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 0;
  width: 1920px;
}
.zhongdengBj img {
  width: 100%;
}
// 预期建设效果
.jianshe {
  width: 100%;
  padding-bottom: 74px;
  background: #f8faff;
}
.jianshe-box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  position: relative;
}
.jianshe-box-shade {
  width: 670px;
  height: 424px;
  background: #0156ff;
  position: absolute;
  left: 528px;
}
.jianshe-box-shade-content {
  padding: 60px 54px;
}
.jianshe-box-shade ul {
  margin-bottom: 65px;
  list-style: inside;
}
.jianshe-box-shade ul li {
  height: 30px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
ul li::marker {
  color: #0156ff;
}
.dian::marker {
  color: #ffffff;
}
// 新灵通心理健康平台
.xlt {
  width: 100%;
  background: #ffffff;
}
.xlt-box {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.center-four-first-title,
.center-four-second-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
  margin-top: 50px;
  margin-bottom: 32px;
}
.center-four-img {
  margin-bottom: 74px;
}
.center-four-fourth {
  width: 282px;
  height: 303px;
  background: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(1, 86, 255, 0.1);
  border-radius: 10px;
}
.center-four-fourth-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  letter-spacing: 3px;
  margin-bottom: 24px;
  text-align: center;
}
.center-four-fourth-text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
}

/deep/.el-tabs__nav-scroll {
  display: flex;
  justify-content: space-around;
  padding-bottom: 22px;
}
/deep/.el-tabs__active-bar {
  margin-bottom: -22px;
}

// 内容居中
.elTabPlane {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.elTabPlane > img {
  width: 1200px;
}
/deep/.el-tabs__item {
  padding: 0px 120px;
  font-size: 20px;
}
/deep/.el-tabs {
  padding-top: 74px;
}
// 应用案例
.content-five {
  width: 100%;
  background: #ffffff;
  padding-bottom: 150px;
}
.center-five {
  width: 100%;
  height: 968px;
  background: #ffffff;
}
.center-five-content {
  width: 1200px;
  margin: 0 auto;
}
.center-five-left {
  width: 542px;
  height: 464px;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(1, 86, 255, 0.1);
  border-radius: 10px;
}
.center-five-right {
  width: 614px;
  height: 432px;
}
.center-five-right-text {
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a5a5a;
  line-height: 17px;
}
.is-active,
.el-carousel__item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.center-five-title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 3px;
  margin: 32px 0px;
  padding: 0 40px;
  text-align: center;
}
.center-five-text {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 3px;
  padding: 0 40px;
}
/deep/.el-carousel__button {
  background: #888;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
}
</style>
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/static/css/global.css';
import BaiduMap from 'vue-baidu-map'; //引入地图导航
import device from 'vue-device-detector';
import VueCoreVideoPlayer from 'vue-core-video-player';

import APlayer from '@moefe/vue-aplayer'
Vue.use(APlayer);

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer) 


import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'

Vue.use(imagePreview)

// your some.vue
export default {
  // ...
  methods:{
    foo(){
    const preview = this.$imagePreview({
        initIndex:0,
        images:['https://yinodimage.oss-cn-hangzhou.aliyuncs.com/20200229004202.jpg'],
      })
    }
  }
}





Vue.use(BaiduMap, {
  ak: 'uhfKv2OqL5TOD9aLCqSbCTWgSaz0hGUj', // ak 是在百度地图开发者平台申请的密钥
});
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(device);
Vue.use(VueCoreVideoPlayer);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // console.log(document)
  next();
});

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');

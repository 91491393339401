<template>
  <div class="box">
    <!-- 导航部分 -->
    <div class="title">
      <div class="logo" @click="toIndex()">
        <img src="../assets/publicImg/logo.png" alt="" />
      </div>
      <div class="nav">
        <ul>
          <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" active-text-color="#0156FF"
            text-color="#000000" router="">
            <el-menu-item index="/index"> 首页 </el-menu-item>
            <el-submenu index="3">
              <template slot="title">方案</template>
              <el-menu-item index="/schemeHigher">高等教育</el-menu-item>
              <el-menu-item index="/schemeTrade">中等职业教育</el-menu-item>
              <el-menu-item index="/schemeBasics">基础教育</el-menu-item>
              <el-menu-item index="/schemeTrain">教师培训</el-menu-item>
            </el-submenu>
            <!-- <el-submenu index="4">
              <template slot="title">产品</template>
              <el-menu-item index="/productSoft">软件</el-menu-item>
              <el-menu-item index="/productHard">硬件</el-menu-item>
            </el-submenu> -->
            <el-submenu index="4">
              <template slot="title">
                <span
                  :style="{ color: $route.name == 'productSoft' || $route.name == 'productHard' ? '#0156FF' : '' }">产品</span>
              </template>
              <div class="my-menu">
                <div class="my-menu-item">
                  <div class="my-menu-item-title">软件</div>
                  <ul>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(0) }" @click="toProductSoft(0)">心理测评系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(1) }" @click="toProductSoft(1)">心理咨询系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(2) }" @click="toProductSoft(2)">课程教学系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(3) }" @click="toProductSoft(3)">活动管理系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(4) }" @click="toProductSoft(4)">访谈管理系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(5) }" @click="toProductSoft(5)">重点关注系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(6) }" @click="toProductSoft(6)">干预管理系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(7) }" @click="toProductSoft(7)">门户管理系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisActive(8) }" @click="toProductSoft(8)">档案管理系统</li>
                  </ul>
                </div>
                <div class="my-menu-item">
                  <div class="my-menu-item-title">硬件</div>
                  <ul>
                    <li
                      :style="{ color: $route.name == 'productHardRobot' || $route.name == 'productHardRobot' ? '#0156FF' : '' }"
                      @click="toProductHardRobot()">智能服务机器人</li>
                    <li
                      :style="{ color: $route.name == 'productHardSelfHelp' || $route.name == 'productHardSelfHelp' ? '#0156FF' : '' }"
                      @click="toProductHardSelfHelp()">心理自助服务系统</li>
                    <li :class="{ 'my-menu-item-active': myMenuisHardActive(0) }" @click="toProductHard(0)">团体咨询室</li>
                    <li :class="{ 'my-menu-item-active': myMenuisHardActive(1) }" @click="toProductHard(1)">情绪宣泄室</li>
                    <li :class="{ 'my-menu-item-active': myMenuisHardActive(2) }" @click="toProductHard(2)">音乐放松室</li>
                    <li :class="{ 'my-menu-item-active': myMenuisHardActive(3) }" @click="toProductHard(3)">心理咨询室</li>
                    <li :class="{ 'my-menu-item-active': myMenuisHardActive(4) }" @click="toProductHard(4)">心理训练室</li>
                    <li :class="{ 'my-menu-item-active': myMenuisHardActive(5) }" @click="toProductHard(5)">沙盘游戏室</li>
                  </ul>
                </div>
              </div>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">内容</template>
              <el-menu-item index="/contentClass">课程资源</el-menu-item>
              <el-menu-item index="/contentSelf">心理微视频</el-menu-item>
            </el-submenu>
            <el-menu-item index="/move">
              <span :style="{ color: $route.name == 'newsDetial' ? '#0156FF' : '' }"> 动态 </span>
            </el-menu-item>
            <el-menu-item index="/aboutUs">关于我们</el-menu-item>
          </el-menu>
          <li></li>
        </ul>
      </div>
    </div>
    <!-- 中心内容出口 -->
    <div>
      <router-view />
    </div>
    <!-- foot部分 -->
    <div class="foot">
      <div class="foot-content">
        <div class="foot-left">
          <div class="school">学银通融(北京)教育科技有限公司</div>
          <div class="address">
            <!-- <div>公司地址：<span class="address-text">{{centerConfig.centerAddr}}</span></div> -->
            <div>
              公司地址：<span class="address-text"><span>北京市海淀区高梁桥斜街59号1号院-中坤大厦1011</span> </span>
            </div>
          </div>
          <div class="phone">电话：<span>400-0055-201</span></div>
          <div class="email">邮箱：<span>service@x-lingtong.com</span></div>
        </div>
        <div class="foot-center">
          <div class="foot-center-title">友情链接</div>
          <div style="display: flex">
            <div class="foot-centerInfo">
              <div class="foot-center-list" style="padding-right: 10px" v-for="firendLinksList in firendLinks"
                :key="firendLinksList.linkId" @click="friendLink(firendLinksList.linkUrl)">
                {{ firendLinksList.linkDesc }}
              </div>
            </div>
          </div>
        </div>
        <div class="foot-right">
          <div class="circle-box">
            <div class="circle">
              <img v-bind:src="centerConfig.wxmpQrcode" />
            </div>
            <div class="circle-text">心灵通小程序</div>
          </div>
          <div class="square-box">
            <div class="square">
              <img v-bind:src="centerConfig.wxQrcode" />
            </div>
            <div class="square-text">心灵通公众号</div>
          </div>
        </div>
      </div>
      <div>
        <div class="foot-publicist">Copyright 2021- {{ year }} 学银通融(北京)教育科技有限公司 . All rights reserved</div>
        <div class="foot-publicist">
          <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2021006657号-6</a>
        </div>
        <div class="foot-publicist" style="display: none">
          <a href="http://www.beian.gov.cn" target="_blank">京公网安备 11010802035955</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      year: 0,
      activeIndex: this.$route.name,
      centerConfig: {},
      firendLinks: [],
      productHardActive: '0',
    }
  },
  watch: {
    $route(val) {
      // this.activeIndex = this.$route.name
      this.$set(this, 'activeIndex', this.$route.name)
    },
  },
  methods: {
    getCateList() {
      get('/index/common').then((res) => {
        this.centerConfig = res.data.centerConfig
        this.firendLinks = res.data.firendLinks
      })
    },
    toIndex() {
      this.$router.push('/index')
    },
    friendLink(e) {
      window.open(e, '_blank')
    },
    myMenuisActive(index) {
      if (this.$route.name == 'productSoft') {
        if (this.$store.state.productSoftActive == index) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    toProductSoft(index) {
      if (this.$route.name == 'productSoft') {
        this.$store.state.productSoftActive = index
      } else {
        this.$router.push({
          name: 'productSoft',
          params: {
            index,
          },
        })
      }
    },
    toProductHard(index) {
      if (this.$route.name != 'productHard') {
        this.$router.push({ name: 'productHard' })
      } else {
        this.productHardActive = index
      }
    },
    myMenuisHardActive(index) {
      if (this.$route.name == 'productHard') {
        if (this.productHardActive == index) {
          return true
        } else {
          return false
        }
      }
    },
    toProductHardSelfHelp() {
      if (this.$route.name != 'productHardSelfHelp') {
        this.$router.push('/productHardSelfHelp')
      } else {
        return false;
      }

    },
    toProductHardRobot() {
      if (this.$route.name != 'productHardRobot') {
        this.$router.push('/productHardRobot')
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getCateList()
    this.year = new Date().getFullYear()
    // this.activeIndex = this.$route.name
  },
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  // background-color: #39a9ed;
  background-color: #4676f8;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

.title {
  width: 1200px;
  height: 74px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  line-height: 84px;
}

.logo>img {
  width: 112px;
  height: 48px;
  margin-top: 15px;
  cursor: pointer;
}

.log>img {
  line-height: 84px;
}

.nav>ul {
  //   display: flex;
  list-style: none;
}

ul li {
  font-size: 16px;
}

/deep/.el-submenu__title {
  color: #3b3d41;
  font-size: 16px;
}

li:hover {
  color: #0156ff !important;
}

/deep/.el-submenu {
  margin-left: 90px;
  font-size: 16px;
}

/deep/.el-menu-item {
  margin-left: 90px;
}

/deep/.el-menu.el-menu--horizontal {
  border: none;
}

a {
  text-decoration: none;
  display: block;
  color: #3b3d41;
}

/deep/.el-menu-item {
  border: none !important;
}

// foot样式
.foot {
  background: #62656d;
  overflow: hidden;
}

.foot-left {
  padding-top: 32px;
  padding-bottom: 32px;
  width: 340px;
  height: 100%;
}

.foot-center {
  margin-left: 88px;
  margin-top: 32px;
  width: 370px;
  align-items: center;
}

.foot-publicist {
  font-size: 14px;
  padding-top: 5px;
  text-align: center;
  color: #eee;
  cursor: pointer;
}

.foot-publicist:nth-child(2n) {
  margin-bottom: 8px;
}

.foot-publicist:last-child {
  padding-bottom: 10px;
}

.foot-publicist>a {
  color: #eee;
}

.school {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 12px;
  margin-left: 27px;
  // cursor: pointer;
  cursor: default;
}

.address {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  margin-left: 27px;
  cursor: default;
  width: 242px;
}

.address-text {
  line-height: 22px;
}

// .address div:nth-child(1) {
//   white-space: nowrap;
// }

.phone {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  margin-left: 27px;
  cursor: default;
  width: 238px;
}

.email {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 27px;
  margin-bottom: 12px;
  cursor: default;
}

.foot-center-title {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
  cursor: default;
}

.foot-centerInfo {
  width: 200px;
  // width: 450px;
  max-height: 100px;
  // 多行文本溢出剪裁
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.foot-center-list {
  width: 200px;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
}

.foot-center-list-info {
  padding-right: 32px;
}

.foot-right {
  width: 291px;
  height: 109px;
  margin-left: 175px;
  display: flex;
  margin-top: 32px;
}

.circle>img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 14px;
}

.circle-text {
  margin-right: 61px;
  margin-bottom: 12px;
  width: 90px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.square-text {
  width: 154px;
  height: 14px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.square-box {
  width: 154px;
  margin-left: 25px;
}

.square>img {
  text-align: center;
  width: 82px;
  height: 82px;
  background-color: white;
  margin-bottom: 14px;
}

.foot-content {
  margin: 0 auto;
  width: 1264px;
  display: flex;
}

/deep/.el-submenu {
  margin: 0px;
}

/deep/ul.el-menu-demo.el-menu--horizontal.el-menu li {
  margin: 0px 20px;
}

/deep/.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

/deep/.el-menu--horizontal {
  margin-top: 10px;
}

/deep/.el-submenu.is-active .el-submenu__title {
  border: none;
}

/deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border: none;
}

/deep/.el-menu--horizontal>.el-submenu .el-submenu__title {
  border: none;
}

.my-menu {
  padding: 30px;
  width: 300px;
  display: flex;
}

.my-menu-item-title {
  color: #0156ff;
  padding-bottom: 15px;
}

.my-menu .my-menu-item {
  width: 150px;
}

.my-menu .my-menu-item ul {
  list-style: none;
}

.my-menu-item ul li {
  padding: 10px 0px;
  cursor: pointer;
}

.my-menu-item-active {
  color: #0156ff;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">产品-硬件</div>
      </div>
    </div>
    <!-- 中心内容 -->
    <div class="content-center-box">
      <div class="content-center">
        <div class="content" v-for="item in arrList" :key="item.id">
          <div class="contentImg">
            <!-- <img :src="item.src" alt=""> -->
            <img :src="item.src" v-image-preview>
          </div>
          <div>
            <p class="content-title">{{ item.title }}</p>
            <p class="content-text">{{ item.text }}</p>
            <p class="content-detail">{{ item.info }}</p>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      arrList: [
        { title: "团体咨询室", src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/productHard-one.png', text: "可配置设备：团体活动包、游戏、素质拓展包、心理挂图及制度", info: "该室可用于提升人际交往能力、合作意识和个人优势开发。也可用于开展团体辅导活动，或者用于教师开展教学研讨、教师培训等活动。" },
        { title: "情绪宣泄室", src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/productHard-two.png', text: "可配置设备：不倒翁人形沙袋、呐喊宣泄系统、打击宣泄系统等", info: "该室可用于通过各种道具和宣泄系统的应用，舒缓个人紧张情绪，让人回归平静。" },
        { title: "音乐放松室", src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/productHard-three.png', text: "可配置设备：音乐治疗自助减压系统软件、心理挂图及制度", info: "该室可用于音乐放松训练，消除悲观、焦虑紧张等负面情绪，改善睡眠质量。该室亦可用于辅助催眠。" },
        { title: "心理咨询室", src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/productHard-four.png', text: "可配置设备：沙发、茶几、绿植、心理图书、心理挂图及制度", info: "该室可用于倾吐心声、认识自我、增强来访者的心理适应能力、心理承受能力。" },
        { title: "心理训练室", src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/productHard-five.png', text: "可配置设备：桌椅、电脑、沙发、系统软件、心理挂图及制度", info: "该室可用于情绪控制训练、抗挫折培训、学习压力管理。 可提升幸福感、改善考试状态、缓解考试焦虑、改善人际关系。" },
        { title: "沙盘游戏室", src: 'https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/productHard-six.png', text: "可配置设备：立柜、桌子、沙盘游戏、心理挂图及制度", info: "该室可通过沙盘游戏的非言语支持，激发自我力量，化解内心冲突与压力。" },
      ]
    }
  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
@import '../css/global.css';

.box {
  background: #EFF4FF;
}

// 中心内容
.topImg-title {
  line-height: 108px;
}

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.content-center-box {
  padding-bottom: 150px;
}

.content-center {
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 60px;
}

.content {
  width: 532px;
  // border: 1px solid;
  box-sizing: border-box;
  background: #f4f8ff;
  margin-bottom: 40px;
}

.contentImg>img {
  height: 210px;
}

.content:first-child {
  margin-top: 50px;
}

.content:nth-child(2) {
  margin-top: 50px;
}

.content-title {
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3b3d41;
  line-height: 28px;
  margin: 24px 0px 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.content-text {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3b3d41;
  line-height: 22px;
  margin-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.content-detail {
  height: 53px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3b3d41;
  line-height: 24px;
  padding-bottom: 42px;
  padding-left: 24px;
  padding-right: 24px;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/move/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">动态</div>
        <div class="topImg-text">从心出发 让生命充满力量</div>
      </div>
    </div>
    <div class="newDetail">
      <div class="newDetail-content">
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <!-- <el-breadcrumb-item :to="{ path: `/${artInfo.catePath}` }">{{artInfo.cateName}}</el-breadcrumb-item> -->
          <el-breadcrumb-item :to="{ path: '/move' }">动态</el-breadcrumb-item>
          <el-breadcrumb-item>动态详情</el-breadcrumb-item>
        </el-breadcrumb>
        <h4 class="newDetail-title">{{ artInfo.title }}</h4>
        <div class="newDetail-time">{{ artInfo.createdAt }}</div>
        <div class="newDetail-bottom"></div>
        <div class="indexNews-text indexNews-text2">
          <p class="box_img" v-html="artInfo.artBody"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItem } from '../utils/storage'
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      artInfo: {},
    }
  },
  methods: {
    getDetail() {
      let articleId = this.$route.params.artId
      get('/article/detail', { articleId: articleId }).then((res) => {
        this.artInfo = res.data
        // document.title = this.artInfo.title + ' - ' + siteConfig.centerName
      })
    },
  },
  mounted() {
    this.getDetail()
  },
}
</script>

<style lang="less" scoped>
.box {
  background: #eff4ff;
  padding-bottom: 150px;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
.topImg {
  display: flex;
  position: relative;
  height: 200px;
}
.topImg > img {
  width: 100%;
}
p {
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  line-height: 32px;
  text-indent: 2rem;
  width: 920px;
}

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 18%;
  top: 45px;
}
.topImg-title {
  font-size: 60px;
  font-family: AlibabaPuHuiTiB;
  color: #ffffff;
  line-height: 82px;
  letter-spacing: 22px;
}
.topImg-text {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 11px;
  margin-top: 17px;
}
.newDetail {
  width: 1200px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 35px;
}
.newDetail-content {
  padding: 40px 56px;
}
h4 {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  line-height: 22px;
  margin: 24px 0px 10px;
}
.newDetail-time {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9da2;
  line-height: 17px;
  margin-bottom: 24px;
}
.newDetail-bottom {
  width: 100%;
  height: 1px;
  background: #e5eaf1;
  margin-bottom: 40px;
}
</style>
<style lang="less">
.box_img {
  width: 1088px;
  img {
    max-width: 80%;
    margin-top: 20px;
  }
  p {
    width: 1088px;
  }
}
h1 {
  margin-bottom: 20px;
}
.indexNews-text2 {
  margin-top: 48px;
  padding-bottom: 48px;
  width: 1088px;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/content/class-top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">心理微视频</div>
        <!-- <div class="topImg-title">大学生心理健康图谱（一）</div> -->
      </div>
    </div>
    <!-- 课程资源主题部分 -->
    <div class="course-box">
      <div class="course">
        <div class="course-nav">
          <div :class="{ 'course-nav-all': true, tabActive: tabActive == 0 }" @click="tabChange(0)"><span>全部</span>
          </div>
          <div :class="{ 'course-nav-list': true, tabActive: tabActive == cateDataItem.cateId }"
            v-for="cateDataItem in cateData" :key="cateDataItem.cateId" @click="tabChange(cateDataItem.cateId)">{{
              cateDataItem.cateName
            }}</div>
        </div>
        <!-- 课程主体部分 -->
        <div class="learnCourse">
          <div class="learnCourse-list hoverLearnCourse hover-item hover-item-bigImg"
            v-for="cataDataListItem in cataDataList" :key="cataDataListItem.id" @click="toClass(cataDataListItem.id)">
            <div class="learnCourse-list-info">
              <div class="learnCourse-list-info-img">
                <img v-bind:src="cataDataListItem.coverImg" alt="" />
              </div>
              <div class="learnCourse-list-info-title hover-item-title">{{ cataDataListItem.title }}</div>
              <div class="video">
                讲师：<span>{{ cataDataListItem.lecturer }}</span> <span class="video-viewCount">{{
                  cataDataListItem.viewCount
                }}</span>次观看
              </div>
            </div>
          </div>
          <div class="no-content" v-if="cataDataList == ''">
            <img src="../assets/home/noContent.png" alt="" />
          </div>
        </div>
        <!-- 分页 -->
        <div v-if="cataDataList.length !== 0">
          <el-pagination :page-size="9" background layout="prev, pager, next" :total="total" pager-count:7
            @current-change="currentChange" :current-page="currentPage"> </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      tabActive: 0,
      cataDataList: [],
      cateData: [],
      total: 0,
      page: 1,
      currentPage: 1, // 当前页码
      cateId: 0
    }
  },
  methods: {
    // 分页
    currentChange(val) {
      console.log('切换后的页码', val)
      this.currentPage = val
      window.scrollTo(0, 0)
      this.getClassList()
    },
    // 请求课程学习栏目
    getCourseNav() {
      get('/smallvideo/category')
        .then((res) => {
          console.log(res.data)
          this.cateData = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getClassList(cateId = 0) {
      get('/smallvideo/videos', { cateId: this.cateId, page: this.currentPage })
        .then((res) => {
          this.cataDataList = res.data.lists
          this.total = res.data.count
        })
        .catch((err) => {
          console.log(err)
        })
    },
    tabChange(cateId) {
      this.cateId = cateId
      this.currentPage = 1
      this.getClassList(cateId)
      this.tabActive = cateId
    },
    toClass(id) {
      this.$router.push(`/videoPlay/${id}`)
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log('to', to)
    console.log('from', from)
    next(async (vm) => {
      if (from.name == "videoPlay") {
        vm.getClassList();
        vm.getCourseNav();
      } else {
        vm.tabActive = 0,
          vm.cataDataList = [],
          vm.cateData = [],
          vm.total = 0,
          vm.page = 1,
          vm.currentPage = 1, // 当前页码
          vm.cateId = 0,
          vm.getClassList();
        vm.getCourseNav();
      }
    });
  },
  mounted() {

    // 页面跳转到最顶部
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  computed: {
    // cataDataListActive() {
    //   let endIndex = this.currentPage * 9
    //   return this.cataDataList.slice(endIndex - 9, endIndex)
    // },
  },
}
</script>

<style lang="less" scoped>
@import './css/style.css';

.topImg-title {
  // font-size: 42px;
  font-size: 60px;
}

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.course-box {
  background: #eff4ff;
}

.course-nav {
  padding-top: 40px;
  padding-bottom: 56px;
  display: flex;
  // flex-flow: wrap;
}

.course {
  //   width: 1080px;
  width: 1200px;
  margin: 0 auto;
}

.course-nav-all {
  height: 22px;
  border-radius: 14px;
  cursor: pointer;
  margin-right: 24px;
  white-space: nowrap;
}

.course-nav-all>span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 14px;
}

.course-nav-list {
  cursor: pointer;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3d41;
  text-align: center;
  margin: 0 12px;
  white-space: nowrap;
  // margin-bottom: 20px;
}

.course-nav-list:hover {
  color: #4676f8;
}

.tabActive {
  color: #4676f8;
  border-radius: 14px;
  box-shadow: 0 0 0 1px #4676f8;
}

.learnCourse {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
}

.learnCourse-list {
  width: 384px;
  height: 326px;
  //   width: 384px;
  //   height: 348px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 32px;
  margin-right: 22px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.learnCourse-list:nth-child(3n) {
  margin-right: 0;
}

.hoverLearnCourse:hover {
  //   width: 384px;
  //   height: 348px;
  width: 384px;
  height: 326px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(70, 118, 248, 0.2);
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

.hover-item:hover .hover-item-title {
  color: #4676f8;
}

.hover-item-bigImg:hover {
  transform: scale(1.02);
}

.learnCourse-list-info-img>img {
  //   width: 348px;
  //   height: 216px;
  width: 348px;
  height: 195px;
  background: #d8d8d8;
  border-radius: 6px;
}

.learnCourse-list-info-title {
  font-size: 16px;
  font-weight: 600;
  color: #3b3d41;
  padding-top: 20px;
  width: 348px;
  overflow: hidden;
  text-overflow: ellipsis; //文本溢出显示省略号
  white-space: nowrap; //文本不会换行
}

.learnCourse-list-info-hot {
  padding-top: 17px;
  font-size: 12px;
  color: #9b9da2;
}

.learnCourse-list-info-hot>img {
  width: 16px;
  height: 18px;
  padding-right: 3px;
  vertical-align: middle;
}

// 分页
.el-pagination {
  text-align: center;
  // padding: 60px 0px 150px;
  padding: 25px 0px 50px;
}

// 暂无内容
.no-content {
  text-align: center;
  padding: 20px;
  margin: 20px auto;
}

.video {
  margin-top: 14px;
  font-size: 14px;
  color: #9b9da2;
}

.video-viewCount {
  padding-left: 20px;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/aboutUs/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">关于我们</div>
        <div class="topImg-text">从心出发 让生命充满力量</div>
      </div>
    </div>
    <!-- 中心内容 -->
    <!-- <div class="center-content"></div> -->
    <div class="center-brief ">
      <div class="brief-box center-one-content pad80">
        <div class="left">
          <p class="title">公司简介</p>
          <p class="title-bor"></p>
          <p class="title2">学银通融（北京）教育科技有限公司</p>
          <div class="text">
            “心灵通”聚焦AI+心理健康，为大中小幼学校提供心理“教育+咨询”数字化、全流程、一站式整体解决方案，是国内专业的心理健康服务平台，承担教育部“高校学生心理健康管理动态分析与会商指导信息化平台”的技术研发与运营服务。
          </div>
        </div>

        <div class="right">
          <p class="title2">社会荣誉</p>
          <div class="text2">
            学习强国教育频道合作伙伴<br>

            中国高等教育学会理事单位<br>

            中国教育技术协会副秘书长单位<br>

            职业教育国家学分银行联盟单位<br>

            国家智慧职教学习平台战略合作单位<br>

            中国社会工作联合会青少年与学校工作委员会会员单位<br>

            中国教育技术协会智慧心理专业委员会（筹）秘书长单位
          </div>
        </div>
      </div>

      <div class="center-brief mgb25 product-bg">
        <div class="brief-box center-one-content">
          <div class="left school-box">
            <p class="title">产品及服务</p>
            <p class="title-bor"></p>
            <div class="product-box">
              <!-- <div class="item">
                <div class="top">
                  <img style="display: block; width: 100%; height: 100%; border-radius: 0; opacity:1; filter: grayscale(0%);" src="../assets/product4.png" alt="">
                </div>
                <div class="bom">
                  <p class="title">测评及数据服务</p>
                  <p>
                    已经覆盖全国2779所高校，成为全国大学生心理健康最权威的数据库，基本掌握了全国范围内大学新生的心理健康总体状况。
                  </p>
                </div>
              </div> -->
              <div class="item">
                <div class="top">
                  <img
                    style="display: block; width: 100%; height: 100%; border-radius: 0; opacity:1; filter: grayscale(0%);"
                    src="../assets/product3.png" alt="">
                </div>
                <div class="bom">
                  <p class="title">数字化工作台</p>
                  <p>
                    包括心理测评、访谈、评估、预约、咨询、转介、危机干预、课程学习、活动管理等不同功能模块，已经服务300多家院校。
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="top">
                  <img
                    style="display: block; width: 100%; height: 100%; border-radius: 0; opacity:1; filter: grayscale(0%);"
                    src="../assets/product2.png" alt="">
                </div>
                <div class="bom">
                  <p class="title">教学资源库系统</p>
                  <p>
                    与阅文集团、中国高等教育出版社、天津大学出版社合作，涵盖2000多册心理健康图书、400多门心理微课、27门国家级、省级金课。
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="top">
                  <img
                    style="display: block; width: 100%; height: 100%; border-radius: 0; opacity:1; filter: grayscale(0%);"
                    src="../assets/product1.png" alt="">
                </div>
                <div class="bom">
                  <p class="title">智能硬件</p>
                  <p>
                    心理健康视频咨询师、沉浸式AR放松仪、陪伴式心理聊天虚拟人、心理健康教育一体机等智能硬件产品，逐步形成体系、规模，落地应用于各级各类教育场景。
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="top">
                  <img
                    style="display: block; width: 100%; height: 100%; border-radius: 0; opacity:1; filter: grayscale(0%);"
                    src="../assets/product5.png" alt="">
                </div>
                <div class="bom">
                  <p class="title">师资培训服务</p>
                  <p>
                    整合行业专家资源，为学校提供涵盖从线上到线下、从理论到实践、从基础到专业的主题培训、系列培训、定制化研讨会及特色工作坊，为心理健康工作保驾护航。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="brief-bottom">
        <div class="center-one-content box">
          <div class="w25 left">
            <p>2000+</p>
            <p>服务高校</p>
          </div>
          <p class="bor"></p>
          <div class="w25">
            <p>500万+</p>
            <p>学生</p>
          </div>
          <p class="bor"></p>
          <div class="w25">
            <p>2000+</p>
            <p>心理图书</p>
          </div>
          <p class="bor"></p>
          <div class="w25 right">
            <p>1000+</p>
            <p>心理视频</p>
          </div>
        </div>
      </div>
    </div>

    <div class="certification mgb80">
      <div class="certification-box center-one-content">
        <div class="left">
          <p class="title">资质</p>
          <p class="title-bor"></p>
          <p class="mgb32">★ 科技型中小企业</p>
          <p class="mgb32">★ 创新型中小企业</p>
          <!-- <p class="mgb32">★ 软件企业认定证书</p> -->
          <p class="mgb32">★ 软件产品等级证书</p>
          <p class="mgb32">★ 国家级高新技术企业</p>
          <p class="mgb32">★ AAA信用等级认证企业</p>
          <p>★ ISO9001质量管理体系认证</p>
        </div>
        <div class="right">
          <div class="wrap">
            <div class="list">
              <img src="../assets/certification/67.png" alt="" />
              <img src="../assets/certification/1.png" alt="" />
              <img src="../assets/certification/2.png" alt="" />
              <img src="../assets/certification/3.png" alt="" />
              <img src="../assets/certification/4.png" alt="" />
              <img src="../assets/certification/5.png" alt="" />
              <img src="../assets/certification/6.png" alt="" />
              <img src="../assets/certification/7.png" alt="" />
              <img src="../assets/certification/8.png" alt="" />
              <img src="../assets/certification/9.png" alt="" />
              <img src="../assets/certification/10.png" alt="" />
              <img src="../assets/certification/11.png" alt="" />
              <img src="../assets/certification/12.png" alt="" />
              <img src="../assets/certification/25.png" alt="" />
              <img src="../assets/certification/26.png" alt="" />
              <img src="../assets/certification/68.png" alt="" />
              <img src="../assets/certification/27.png" alt="" />
              <img src="../assets/certification/28.png" alt="" />
              <img src="../assets/certification/33.png" alt="" />
              <img src="../assets/certification/34.png" alt="" />
              <img src="../assets/certification/35.png" alt="" />
              <img src="../assets/certification/36.png" alt="" />
              <img src="../assets/certification/42.png" alt="" />
              <img src="../assets/certification/43.png" alt="" />
            </div>
          </div>
          <div class="wrap">
            <div class="list list2">
              <img src="../assets/certification/13.png" alt="" />
              <img src="../assets/certification/14.png" alt="" />
              <img src="../assets/certification/15.png" alt="" />
              <img src="../assets/certification/16.png" alt="" />
              <img src="../assets/certification/17.png" alt="" />
              <img src="../assets/certification/70.png" alt="" />
              <img src="../assets/certification/18.png" alt="" />
              <img src="../assets/certification/19.png" alt="" />
              <img src="../assets/certification/20.png" alt="" />
              <img src="../assets/certification/21.png" alt="" />
              <img src="../assets/certification/72.png" alt="" />
              <img src="../assets/certification/22.png" alt="" />
              <img src="../assets/certification/23.png" alt="" />
              <img src="../assets/certification/24.png" alt="" />
              <img src="../assets/certification/29.png" alt="" />
              <img src="../assets/certification/30.png" alt="" />
              <img src="../assets/certification/31.png" alt="" />
              <img src="../assets/certification/71.png" alt="" />
              <img src="../assets/certification/32.png" alt="" />
              <img src="../assets/certification/37.png" alt="" />
              <img src="../assets/certification/38.png" alt="" />
              <img src="../assets/certification/39.png" alt="" />
              <img src="../assets/certification/40.png" alt="" />
              <img src="../assets/certification/41.png" alt="" />
            </div>
          </div>
          <div class="wrap">
            <div class="list list3">
              <img src="../assets/certification/44.png" alt="" />
              <img src="../assets/certification/45.png" alt="" />
              <img src="../assets/certification/46.png" alt="" />
              <img src="../assets/certification/47.png" alt="" />
              <img src="../assets/certification/48.png" alt="" />
              <!-- <img src="../assets/certification/66.png" alt="" /> -->
              <img src="../assets/certification/49.png" alt="" />
              <img src="../assets/certification/50.png" alt="" />
              <img src="../assets/certification/51.png" alt="" />
              <img src="../assets/certification/52.png" alt="" />
              <img src="../assets/certification/73.png" alt="" />
              <img src="../assets/certification/53.png" alt="" />
              <img src="../assets/certification/54.png" alt="" />
              <img src="../assets/certification/55.png" alt="" />
              <img src="../assets/certification/56.png" alt="" />
              <img src="../assets/certification/57.png" alt="" />
              <img src="../assets/certification/58.png" alt="" />
              <img src="../assets/certification/59.png" alt="" />
              <img src="../assets/certification/60.png" alt="" />
              <img src="../assets/certification/61.png" alt="" />
              <img src="../assets/certification/62.png" alt="" />
              <img src="../assets/certification/63.png" alt="" />
              <img src="../assets/certification/64.png" alt="" />
              <img src="../assets/certification/65.png" alt="" />
              <img src="../assets/certification/69.png" alt="" />

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center-brief mgb25">
      <div class="brief-box center-one-content">
        <div class="left school-box">
          <p class="title">合作学校</p>
          <p class="title-bor"></p>
          <div>
            <img class="mgr80 img-1" src="../assets/school/1-active.png" alt="" />
            <img class="mgr80 img-2" src="../assets/school/2-active.png" alt="" />
            <img class="mgr80" src="../assets/school/3-active.png" alt="" />
            <img class="mgr80" src="../assets/school/4-active.png" alt="" />
            <img class="mgr80" src="../assets/school/5-active.png" alt="" />
            <img class="mgr80" src="../assets/school/6-active.png" alt="" />
            <img class="mgr80" src="../assets/school/7-active.png" alt="" />
            <img class="mgr80" src="../assets/school/8-active.png" alt="" />
            <img src="../assets/school/37.png" alt="" />
            <img class="mgr80 img-10" src="../assets/school/10.png" alt="" />
            <img class="mgr80 img-99" src="../assets/school/13.png" alt="" />
            <img class="mgr80 img-2" src="../assets/school/11.png" alt="" />
            <img class="mgr80 img-25" src="../assets/school/12.png" alt="" />

            <img class="mgr80" src="../assets/school/14.png" alt="" />
            <img class="mgr80" src="../assets/school/15.png" alt="" />
            <img class="mgr80" src="../assets/school/16.png" alt="" />
            <img class="mgr80" src="../assets/school/17.png" alt="" />
            <img src="../assets/school/18.png" alt="" />
            <img class="mgr80" src="../assets/school/19.png" alt="" />
            <img class="mgr80 img-2" src="../assets/school/20.png" alt="" />
            <img class="mgr80" src="../assets/school/21.png" alt="" />
            <img class="mgr80" src="../assets/school/22.png" alt="" />
            <img class="mgr80" src="../assets/school/23.png" alt="" />
            <img class="mgr80" src="../assets/school/24.png" alt="" />
            <img class="mgr80 img-25" src="../assets/school/25.png" alt="" />
            <img class="mgr80" src="../assets/school/26.png" alt="" />
            <img src="../assets/school/27.png" alt="" />
            <img class="mgr80" src="../assets/school/28.png" alt="" />
            <img class="mgr80 img-2" src="../assets/school/29.png" alt="" />
            <img class="mgr80" src="../assets/school/30.png" alt="" />
            <img class="mgr80" src="../assets/school/31.png" alt="" />
            <img class="mgr80" src="../assets/school/32.png" alt="" />
            <img class="mgr80" src="../assets/school/33.png" alt="" />
            <img class="mgr80" src="../assets/school/34.png" alt="" />
            <img class="mgr80 img-99" src="../assets/school/35.png" alt="" />
            <img src="../assets/school/36.png" alt="" />

          </div>
        </div>
      </div>
    </div>
    <div class="center-two">
      <div class="center-one-content">
        <div>
          <div class="plan-titleBar">招贤纳士</div>
          <div class="border-bottom"></div>
        </div>
        <div class="center-two-img">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/aboutUs/two.png" alt="">
          <div class="shade">
            <p>公司处于快速发展期，提供有竞争力的薪酬待遇，欢迎优秀人才加盟。</p>
          </div>
        </div>
        <div class="center-two-tetx">
          <div>
            <p class="center-two-title">JAVA开发工程师：</p>
            <p class="center-two-info">负责公司软件产品的开发工作</p>
          </div>
          <div>
            <p class="center-two-title">内容策划:</p>
            <p class="center-two-info">负责心理学相关课程和内容产品的策划、组织和实施</p>
          </div>
          <div>
            <p class="center-two-title">市场营销:</p>
            <p class="center-two-info">负责在各省进行心理健康相关产品的推广工作</p>
          </div>
        </div>
      </div>
    </div>
    <div class="center-three">
      <div class="center-one-content">
        <div>
          <div class="plan-titleBar">联系我们</div>
          <div class="border-bottom"></div>
        </div>
        <!-- 地图 -->
        <div class="center-three-map">
          <!-- <template>
                                                                        <baidu-map
                                                                            class="bm-view"
                                                                            :center="center"
                                                                            :zoom="zoom"
                                                                            :scroll-wheel-zoom="true"
                                                                          ></baidu-map>
                                                                      </template> -->
          <template>
            <baidu-map class="map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler">
              <bm-marker :position="pixel" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                <bm-label :content="content" :labelStyle="{ color: 'red', fontSize: '24px' }"
                  :offset="{ width: -35, height: 30 }" />
              </bm-marker>
            </baidu-map>
          </template>
        </div>
        <div class="center-three-bottom">
          <div>
            <div class="center-three-bottom-box">
              <div class="center-three-bottom-icon"><img src="../assets/aboutUs/phone.png" alt=""></div> 电话：400-0055-201
            </div>
          </div>
          <div>
            <div class="center-three-bottom-box">
              <div class="center-three-bottom-icon"><img src="../assets/aboutUs/adress.png" alt=""></div>
              邮箱：service@x-lingtong.com
            </div>
          </div>
          <div>
            <div class="center-three-bottom-box">
              <div class="center-three-bottom-icon"><img src="../assets/aboutUs/e-mail.png" alt=""></div>
              公司地址：北京市海淀区高梁桥斜街59号1号院-中坤大厦1011
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      center: { lng: 0, lat: 0 }, //经纬度
      zoom: 3, //地图展示级别
      pixel: { lng: 116.361317, lat: 39.928825 },  //标识
      content: "学银通融（北京）教育科技有限公司"
    }
  },
  methods: {
    handler ({ BMap, map }) {
      this.center = { lng: 116.361317, lat: 39.928825 }
      // this.pixel = {lng: 116.361317, lat: 39.928825}
      this.zoom = 18
    }
  }
};
</script>

<style lang="less" scoped>
@import './css/style.css';

.mgb80 {
  margin-bottom: 80px;
}

.mgb15 {
  margin-bottom: 15px;
}

.mgb32 {
  margin-bottom: 32px;
}

.mgr80 {
  margin-right: 80px;

}

.mgb25 {
  margin-bottom: 25px;
}

.center-brief {

  //公司简介
  .brief-box {
    min-height: 200px;
    display: flex;
    justify-content: space-between;

    .left,
    .school-box {
      width: 45%;
      height: 100%;

      .title {
        width: 200px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #3B3D41;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .title-bor {
        height: 4px;
        width: 80px;
        background: #4676f8;
        margin-bottom: 30px;
      }

      img {
        width: 60px;
        height: 60px;
        // filter: grayscale(100%);
        // opacity: 0.5;
        // background-color: gray;
        border-radius: 50%;
        margin-bottom: 25px;
      }

      .img-10 {
        background-color: rgba(128, 128, 128, 0);
      }

      // .img-2 {
      //   opacity: 0.4;
      // }

      // .img-1 {
      //   opacity: 0.8;
      // }

      // .img-25 {
      //   opacity: 0.4;
      //   background-color: rgba(128, 128, 128, 0);
      // }

      // .img-99 {
      //   opacity: 0.6;
      // }

      img:hover {
        filter: grayscale(0%);
        box-shadow: 0 0 10px rgb(198, 211, 249);
        background-color: rgba(128, 128, 128, 0);
        opacity: 1;
      }
    }

    .school-box {
      width: 100%;
    }

    .right {
      width: 45%;
      height: 100%;
      padding-top: 92px;
    }

    .title2 {
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 600;
      color: #3B3D41;
    }

    .text {
      font-weight: 400;
      font-size: 16px;
      color: #3B3D41;
      line-height: 45px;
    }

    .text2 {
      font-weight: 400;
      font-size: 16px;
      color: #3B3D41;
      line-height: 24px;
    }
  }

  .brief-bottom {
    height: 100px;
    margin-top: 70px;
    background: #0156FF;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #fff;
    font-size: 32px;

    .box {
      background: #0156FF;
      display: flex;
      align-items: center;

      .bor {
        width: 3px;
        height: 50px;
        margin: 0 120px;
        background: #fff;
      }
    }

    .w25 {
      width: 200px;
      text-align: center;

      p:last-child {
        // font-weight: 500;
        font-size: 18px;
      }
    }

    .w25:last-child {
      border: none;
    }
  }
}

.certification {
  height: 700px;
  background: #F9FBFF;
  display: flex;
  justify-content: space-between;

  .certification-box {
    height: 700px;
    background: #F9FBFF;
    display: flex;
    justify-content: space-between;

    .left {
      width: 45%;
      height: 100%;
      padding-top: 80px;

      .title {
        width: 200px;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #3B3D41;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .title-bor {
        height: 4px;
        width: 80px;
        background: #4676f8;
        margin-bottom: 30px;
      }
    }

    .right {
      width: 45%;
      height: 100%;
      // border: solid 1px #000;
      display: flex;

      .wrap {
        width: 180px;
        height: 100%;
        overflow: hidden;
        position: relative;
        // border: solid 1px #000;
      }

      .list {
        list-style: none;
        width: 180px;
        height: 100%;
        position: absolute;
        top: 0px;
        /*right:0px;向右*/
        left: 0px;
        /*向左*/
        animation: mymove 90s 1s infinite linear;
        // display: flex;
        // align-items: center;
      }

      .list2 {
        list-style: none;
        width: 180px;
        height: 100%;
        position: absolute;
        top: 0px;
        /*right:0px;向右*/
        left: 0px;
        /*向左*/
        animation: mymove 110s 1s infinite linear;
        // display: flex;
        // align-items: center;
      }

      .list3 {
        list-style: none;
        width: 180px;
        height: 100%;
        position: absolute;
        top: 0px;
        /*right:0px;向右*/
        left: 0px;
        /*向左*/
        animation: mymove 96s 1s infinite linear;
        // display: flex;
        // align-items: center;
      }

      .list,
      .list2,
      .list3 {
        img {
          display: block;
          width: 170px;
          min-height: 40px;
          object-fit: contain;
        }
      }

      @keyframes mymove {
        from {
          top: 0px;
        }

        to {
          top: -4500px;
        }
      }

      .list:hover {
        animation-play-state: paused;
        cursor: pointer;
      }
    }
  }
}

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 18%;
  top: 30%;
}

// 中心内容
.center-content {
  width: 1200px;
  margin: 0 auto;
  height: 2000px;
  background: #ffffff;
}

.center-one {
  width: 100%;
  padding-bottom: 74px;
  background-color: #ffffff;
  // height: 887px;
}

.center-one-content {
  width: 1200px;
  margin: 0 auto;
}

.certification>img {
  // width: 1219px;
  width: 1212px;
  height: 732px;
  margin-top: 50px;
}

.displayBox {
  display: flex;
  // align-items: center;
  margin-bottom: 16px;
}

.listIcon>img {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.list-text {
  font-size: 16px;
  font-weight: 400;
  color: #3B3D41;
}

.plan-titleBar {
  height: 56px;
  font-size: 40px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 56px;
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 74px;
  // display: flex;
  // justify-content: space-around;
}

.border-bottom {
  width: 80px;
  height: 4px;
  background: #4676f8;
}

h3 {
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  text-align: center;
  margin-bottom: 32px;
}

.center-two {
  width: 100%;
  padding-bottom: 74px;
  background-color: #D9E7FF;
}

.shade {
  width: 490px;
  height: 340px;
  background: rgba(1, 86, 255, 0.66);
  position: absolute;
  display: flex;
  align-items: center;
}

.shade>p {
  padding: 0 45px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #FFFFFF;
  line-height: 32px;
  letter-spacing: 1px;
  line-height: 38px;
}

.center-two-img {
  width: 1200px;
  margin: 0 auto;
  margin-top: 42px;
  display: flex;
  flex-direction: row-reverse;
}

.center-two-img>img {
  width: 1200px;
}

.center-two-tetx {
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.center-two-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.center-two-text {
  font-size: 16px;
}

.center-three {
  width: 100%;
  background-color: #ffffff;
}

.center-three-map {
  width: 1200px;
  height: 300px;
  background-color: plum;
  margin: 50px auto;
}

.center-three-bottom {
  width: 1200px;
  height: 50px;
  border-top: 1px solid #E1E1E1;
  border-bottom: 1px solid #E1E1E1;
  margin: 0 auto;
  margin-bottom: 150px;
  display: flex;
  justify-content: space-around;
  line-height: 50px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6F7174;
}

.center-three-bottom-box {
  display: flex;
  align-items: center;
}

.center-three-bottom-icon {
  line-height: 17px;
  margin-right: 8px;
}

// 地图
.map {
  width: 100%;
  height: 300px;
}

/deep/.BMapLabel {
  font-size: 16px !important;
  color: #6F7174 !important;
  border: 1px solid #a7a9ac !important;
}

.mgt80 {
  // margin-top: 80px;
  padding: 80px 0;
  background: #FAFCFF;
}

.pad80 {
  padding: 80px 0;
}

.product-bg {
  padding-top: 80px;
  background: #FAFCFF;
}

.product-box {
  min-height: 300px;
  display: flex;
  justify-content: space-between;

  .item {
    width: 276px;

    .top {
      width: 100%;
      height: 147px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .bom {
      height: 156px;
      padding: 22px 12px;

      .title {
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        line-height: 28px;
      }
    }

    .bom:hover {
      background: #7BA7FF;
      color: #fff;

      .title {
        color: #fff;
      }
    }
  }
}
</style>
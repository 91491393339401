<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/scheme/top-img-one.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">中等职业教育</div>
        <div class="topImg-text">心理危机筛查与干预解决方案</div>
      </div>
    </div>
    <!-- one -->
    <div class="center-one">
      <div class="center-one-content">
        <h1>政策背景</h1>
        <div>
          <div class="center-one-contentImg">
            <img src="../assets/scheme/trade-one.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- two -->
    <div class="center-two">
      <div class="center">
        <h1>中等职业教育心理健康解决方案</h1>
        <div class="center-img">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/trade-two.png" alt="" />
        </div>
      </div>
    </div>
    <!-- three -->
    <div class="center-three">
      <div>
        <h1>解决方案交付内容</h1>
        <template>
          <el-tabs v-model="activeName" active-text-color="#0156FF">
            <el-tab-pane label="心理测评工作平台" name="first">
              <div class="center-threeImg">
                <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/trade-three-first.png" alt="" />
              </div>
            </el-tab-pane>
            <el-tab-pane label="多样化测评量表" name="second">
              <div style="margin-top: 50px">
                <p class="center-three-title">平台量表分为两种：</p>
                <p class="center-three-text">1.结合中等职业学生特点，提供丰富的、多样化的通用型量表，数量不低于100个。</p>
                <p class="center-three-text">2.个性化量表：针对中等职业教育特点设计的极端心理、校园霸凌、人际关系、学习困难四大画像量表，包含42个子画像维度，量表基于国际通用量表，根据本土化测试特点进行设计而成，同时支持个性化量表，下表为部分关键子画像维度说明。</p>
                <div style="display: flex; justify-content: space-around; margin-top: 50px">
                  <div>
                    <img src="../assets/scheme/trade-three-left.png" alt="" />
                    <p style="text-align: center; margin-bottom: 94px">通用型量表库</p>
                  </div>
                  <div>
                    <img src="../assets/scheme/trade-three-right.png" alt="" />
                    <p style="text-align: center; margin-top: 34px">个性化量表</p>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="全方位心理健康教育" name="third">
              <div style="margin-top: 50px">
                <p class="center-three-text">根据中等职业教育心理危机筛查与干预的特点，以公共课、公开课、微课、主题课、直播课、心理自助等形式满足老师教育教学和 心理健康教育的需求，支持学生通过pc、小程序的形式进行学习。</p>
                <div style="display: flex; justify-content: space-evenly; margin-top: 50px">
                  <div>
                    <img src="../assets/scheme/trade-fourth-left.png" alt="" />
                    <p style="text-align: center; margin-bottom: 94px">PC端开展教学</p>
                  </div>
                  <div>
                    <img src="../assets/scheme/trade-fourth-right.png" alt="" />
                    <p style="text-align: center; margin-top: 42px">小程序课程学习</p>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="个性化师资培训" name="fourth">
              <div class="center-four-img">
                <p class="center-four-second-title">根据高等教育、中等职业教育和基础教育的特点，针对管理部门、咨询师、课程授课教师、辅导员等角色提供专业的培训课程体系， 激活教师团队，提升本土化的心理危机识别与干预能力；培训分为线上培训和线下培训两种模式，支持为学校定制个性化培训方案。</p>
                <div class="alignItemsCenter">
                  <div class="center-four-third-left">
                    <div style="display: flex; margin-top: 40px; margin-left: 55px">
                      <div class="alignItemsCenter left-box">
                        <img src="../assets/scheme/four-third-one.png" alt="" />
                        <div class="center-four-third-left-title">基本素养培训</div>
                      </div>
                      <div>
                        <ul>
                          <li>心理危机筛查与干预相关政策</li>
                          <li>基础教育常见心理问题及处理办法</li>
                          <li>心理危机筛查和干预过程中的注意事项</li>
                        </ul>
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 40px; margin-left: 55px">
                      <div class="alignItemsCenter left-box">
                        <img src="../assets/scheme/four-third-two.png" alt="" />
                        <div class="center-four-third-left-title">风险筛选技能培养</div>
                      </div>
                      <div>
                        <ul>
                          <li>基础教育心理危机筛查与干预主要流程和工作内容</li>
                          <li>学生一对一访谈和风险识别培训</li>
                          <li>心理咨询常见问题及方法</li>
                        </ul>
                      </div>
                    </div>
                    <div style="display: flex; margin-top: 40px; margin-left: 55px">
                      <div class="alignItemsCenter left-box">
                        <img src="../assets/scheme/four-third-three.png" alt="" />
                        <div class="center-four-third-left-title">管理和技术培训</div>
                      </div>
                      <div>
                        <ul>
                          <li>心理危机筛查与干预整体流程培训</li>
                          <li>心理危机筛查与干预管理员培训</li>
                          <li>心理测评培训</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="center-four-third-right">
                    <img src="../assets/scheme/center-four-third-right.png" alt="" />
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <div class="zhongdengBj"> <img src="../assets/scheme/zhongdeng-Bj.png" alt=""> </div> -->
            <el-tab-pane label="全流程技术支撑" name="fifth">
              <div class="zhongdengBj"><img src="../assets/scheme/zhongdeng-Bj.png" alt="" /></div>
              <div class="center-four-img">
                <p class="center-four-second-title">通过应用培训、线下支持、电话答疑、普测报告等方式帮助学校解决在普测过程中的一系列技术与应用问题。</p>
                <div style="display: flex; justify-content: space-between">
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/four-fourth-one.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">应用培训</div>
                      <p class="center-four-fourth-text">通过线上、线下等方式对管理员、教师和学生进行培训，提供产品操作视频、应用视频等资源。</p>
                    </div>
                  </div>
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/four-fourth-two.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">平台运维</div>
                      <p class="center-four-fourth-text">通过SaaS方式提供服务，平台、硬件、资源服务化，学校投入少，负担小。平台功能、性能实时优化，保障用户使用体验。</p>
                    </div>
                  </div>
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/four-fourth-three.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">技术支持</div>
                      <p class="center-four-fourth-text">通过线上线下两种方式提供覆盖全流程的技术支持服务，解决管理员、教师和学生在使用中的问题，保障测评工作的顺利开展。</p>
                    </div>
                  </div>
                  <div class="center-four-fourth">
                    <div style="padding: 0 32px">
                      <div style="margin: 40px 0px 14px; text-align: center">
                        <img src="../assets/scheme/four-fourth-four.png" alt="" />
                      </div>
                      <div class="center-four-fourth-title">分析报告</div>
                      <p class="center-four-fourth-text">对普测进行全方位、多维度的数据分析，提供专业的分析报告，便于教育局和学校总结问题，进行针对性优化，建立测评优化长效机制。</p>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
    <!-- four -->
    <div class="center-four">
      <div class="center-four-content">
        <h1>应用模式</h1>
        <p class="center-four-title">通知学生在规定时间范围内完成心理普查，常见有两种模式：自由式测评和集中测评</p>
        <div class="center-one-contentImg">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/scheme/schemeTrade-yingyong.png" alt="" />
        </div>
      </div>
    </div>
    <!-- five -->
    <div class="center-five" style="display: none">
      <div class="center-five-content">
        <h1>应用案例</h1>
        <div style="display: flex; justify-content: space-around; width: 1200px; margin: 0 auto">
          <div class="center-five-left">
            <div style="text-align: center; margin-top: 32px">
              <img src="../assets/scheme/nanyangSchool.png" alt="" />
            </div>
            <h5 class="center-five-title">天津市南洋工业学校</h5>
            <p class="center-five-text">
              学校采用集中测评的模式，通过心灵通平台在大课间针对学校1600余名学生开展心理普查，这种方式集中时间，统一通知，效率高，速度快，成果明显。 学生通过移动端参与测评简单方便，15分钟就完成了90%学生的测评。系统提供测评报告和筛查名单，学校可以根据测评结果开展后续跟进工作。
            </p>
          </div>
          <div class="center-five-right">
            <div class="block">
              <p class="center-five-right-text">此为测试示意图，不是用户应用截图</p>
              <el-carousel width="100%" height="462px" :autoplay="false" arrow="never" indicator-position="outside">
                <el-carousel-item v-for="item in imgs" style="height: 466px, width:282px" :key="item.url">
                  <img :src="item.url" alt="" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      imgs: [{ url: require('../assets/scheme/lunboOne-1.png') }, { url: require('../assets/scheme/lunboOne-2.png') }],
    }
  },
}
</script>

<style lang="less" scoped>
@import '../css/global.css';

.topImg-title {
  line-height: 32px;
}
.topImg-headline {
  top: 70px;
}
.topImg-title,
.topImg-text {
  font-weight: bold;
  font-size: 36px;
}
.center-one {
  width: 100%;
  background-color: #ffffff;
}
.center-content {
  width: 1200px;
  margin: 0 auto;
  height: 2000px;
  background: #ffffff;
}
.center-one {
  width: 100%;
  height: 736px;
  background-color: #ffffff;
}
.center-one-content {
  width: 1200px;
  margin: 0 auto;
  height: 2000px;
  background: #ffffff;
}
.center-one-contentImg > img {
  width: 1200px;
}
h1 {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 6px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 50px;
}
.center-one-content-title {
  text-align: center;
  margin-top: 24px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
}
.center-two {
  width: 100%;
  height: 578px;
  background-color: #f8faff;
}
.center-three {
  width: 100%;
  background-color: #ffffff;
}
.center-threeImg {
  margin-top: 50px;
  margin-bottom: 74px;
}
.center-threeImg > img {
  width: 1200px;
}

.center {
  margin: 0 auto;
  width: 1200px;
}
.center-img > img {
  width: 1200px;
}

/deep/.el-tabs__nav-scroll {
  overflow: hidden;
  display: flex;
  width: 1200px;
  margin: 0 auto;
}
/deep/.el-tabs__item {
  padding: 0px 50px;
  height: 50px;
  font-size: 20px;
  letter-spacing: 1px;
}
/deep/.el-tab-pane {
  width: 1200px;
  margin: 0 auto;
}
.zhongdengBj {
  position: absolute;
  bottom: -4px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 0;
  width: 1920px;
}
.zhongdengBj img {
  width: 100%;
}

/deep/.el-tabs__header {
  margin: 0px;
}
.center-three-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  letter-spacing: 3px;
}
.center-three-text {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: black;
  letter-spacing: 3px;
}
// 个性化师资培训
.alignItemsCenter {
  display: flex;
  align-items: center;
}
.left-box {
  width: 280px;
}
.center-four-icon {
  width: 40px;
  height: 25px;
}
.center-four {
  width: 100%;
  // height: 710px;
  background-color: #f8faff;
}
.center-four-content {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
}
.center-four-content > img {
  width: 1200px;
}

.center-four-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 28px;
}
.center-four-first-title,
.center-four-second-title {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
  margin-top: 50px;
  margin-bottom: 32px;
  text-align: center;
}
.center-four-third-left {
  width: 752px;
  height: 448px;
  background: #ffffff;
  box-shadow: 6px 7px 13px 0px rgba(1, 86, 255, 0.09);
}
.center-four-third-left-title {
  margin-left: 16px;
  margin-right: 98px;
  white-space: nowrap;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  letter-spacing: 3px;
}
ul > li {
  width: 374px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  line-height: 32px;
  letter-spacing: 2px;
  list-style: inside;
}
ul li::marker {
  color: #0156ff;
}
.center-four-third-right > img {
  width: 462px;
  height: 308px;
  background-color: plum;
}
.center-four-img {
  margin-bottom: 74px;
  position: relative;
  z-index: 1;
}
.center-four-fourth {
  width: 282px;
  height: 303px;
  background: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(1, 86, 255, 0.1);
  border-radius: 10px;
}
.center-four-fourth-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0156ff;
  letter-spacing: 3px;
  margin-bottom: 24px;
  text-align: center;
}

.center-four-fourth-text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 2px;
}
// 全流技术支撑

// 表格部分
table,
table tr th,
table tr td {
  border: 1px dashed #0156ff;
  font-size: 18px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}

table tr td {
  height: 50px;
}
th {
  height: 80px;
  background-color: #0156ff;
  color: #ffffff;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 3px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}
.tab-one,
.tab-two {
  color: #3b3d41;
  letter-spacing: 3px;
  text-align: center;
}

.center-five {
  width: 100%;
  background: #ffffff;
}
.center-five-content {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 150px;
}

.center-five-left {
  width: 542px;
  height: 464px;
  background: #ffffff;
  box-shadow: 0px 2px 11px 0px rgba(1, 86, 255, 0.1);
  border-radius: 10px;
}
.center-five-right {
  width: 614px;
  height: 432px;
  display: flex;
  justify-content: center;
}
.center-five-right-text {
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a5a5a;
  line-height: 17px;
}
.is-active {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.center-five-title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  letter-spacing: 3px;
  margin: 32px 0px;
  padding: 0 40px;
  text-align: center;
}
.center-five-text {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  letter-spacing: 3px;
  padding: 0 40px;
}
/deep/.el-carousel {
  height: 491px;
  width: 614px;
}
/deep/.el-carousel__button {
  background: #888;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
}
</style>
<template>
  <div class="box">
    <div class="topImg">
      <img src="../assets/move/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">动态</div>
        <div class="topImg-text">从心出发 让生命充满力量</div>
      </div>
    </div>
    <div class="move-content-box">
      <div class="move-content">
        <div class="content-box content-box-hover" v-for="artListItem in artList" :key="artListItem.id"
          @click="toNoticeList(artListItem.id)">
          <div class="content-img">
            <img v-bind:src="artListItem.imgUrl" alt="" />
          </div>
          <div class="content-right">
            <div style="display: flex">
              <el-tag>{{ artListItem.cateName }}</el-tag>
              <p class="content-title">{{ artListItem.title }}</p>
            </div>
            <p class="content-text">{{ artListItem.summary }}</p>
            <div style="display: flex">
              <p class="content-time">{{ artListItem.createdAt }}</p>
              <p class="content-hot">
                <img src="../assets/move/watch-icon.png" alt="" /> <span>{{ artListItem.testNum }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="no-content" v-if="artList.length == 0">
          <img src="../assets/home/noContent.png" alt="" />
        </div>
        <!-- 分页 -->
        <div v-if="artList.length != 0">
          <el-pagination :page-size="10" background layout="prev, pager, next" :total="total" pager-count:7
            @current-change="currentChange" :current-page="currentPage"> </el-pagination>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { post, get } from '../utils/xajax'
export default {
  data() {
    return {
      artList: [],
      currentPage: 1, // 当前页码
      total: 0,
    }
  },
  methods: {
    currentChange(val) {
      // console.log('切换后的页码', val)
      this.currentPage = val
      window.scrollTo(0, 0)
      this.getNewsList()
    },
    // 请求文章列表
    getNewsList() {
      let cateName = this.$route.name
      get('/article/lists', { cateName: cateName, page: this.currentPage })
        .then((res) => {
          this.artList = res.data.artList
          this.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toNoticeList(id) {
      this.$router.push({ path: `/newsDetial/${id}` })
    },
  },
  mounted() {
    this.getNewsList()
    // 页面跳转到最顶部
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
}
</script>

<style lang="less" scoped>
@import '../css/global.css';

.box {
  background: #eff4ff;
}

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.move-content {
  width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 28px;
}

.move-content-box {
  padding-bottom: 150px;
}

.content-box {
  padding: 32px 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
}

.no-content {
  text-align: center;
  padding: 20px;
}

.content-box-hover:hover {
  background: #fafbff;
}

.content-box:nth-last-child(2) {
  border-bottom: none;
}

.content-box:hover .content-title {
  color: #0156ff;
}

.content-img>img {
  width: 329px;
  height: 194px;
}

.content-right {
  width: 736px;
  margin-left: 24px;
}

.content-title {
  font-size: 19px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #3b3d41;
  margin: 0;
  width: 100%;
  margin-left: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-text {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9da2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-top: 14px;
  margin-bottom: 25px;
}

.content-time,
.content-hot {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9da2;
}

.content-hot {
  margin-left: 36px;
}

/deep/.el-tag {
  color: #4676f8;
  border-color: #0156ff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0156ff;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 28px;
  padding-bottom: 56px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0156ff;
}

/deep/.el-pagination.is-background .btn-prev:disabled {
  background-color: white;
  color: #3b3d41;
  border: 1px solid #e1e1e1;
}

/deep/.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: white;
  color: #3b3d41;
  border: 1px solid #e1e1e1;
}

/deep/.el-icon-arrow-right::before {
  color: #3b3d41;
}
</style>

<template>
  <div class="box">
    <div class="video-box">
      <div class="nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/contentSelf' }">自助资源</el-breadcrumb-item>
          <el-breadcrumb-item>{{ videoDetail.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="videoPlay">
        <div class="video-title">{{ videoDetail.title }}</div>
        <!-- 讲师，观看次数，时间 -->
        <div class="video-header">
          <p>
            <span>讲师：{{ videoDetail.lecturer }}</span>
          </p>
          <p>
            <span>观看次数：{{ videoDetail.viewCount }}</span>
          </p>
          <p>
            <span>时间：{{ videoDetailCreatedAt }}</span>
          </p>
        </div>
        <div class="video-play">
          <div class="player-container">
            <video-player v-if="videoDetail.mediaType == 2" class="video-player-box"
              :class="{ 'video-player-box-audio': videoDetail.mediaType == 2 }" ref="videoPlayer" :options="playerOptions"
              :playsinline="true" customEventName="customstatechangedeventname" @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)" @statechanged="playerStateChanged($event)">
            </video-player>

            <div v-else id="artplayer" class="video-player-box"></div>
          </div>

        </div>
        <div class="tetailIntroduce" v-html="videoDetail.summary"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import 'video.js/dist/video-js.css'
import { post, get } from '../utils/xajax'
import Artplayer from 'artplayer';

import { videoPlayer } from 'vue-video-player'
Vue.component('video-player', videoPlayer)

import aplayer from "vue-aplayer";

export default {
  data() {
    return {
      audio: {},
      videoDetail: {},
      playerOptions: {},
      videoDetailCreatedAt: '',
      artplayer: null,
    }
  },
  //事件方法
  methods: {
    getClassList() {
      let id = this.$route.params.id
      get('/smallvideo/detail', { id: id })
        .then((res) => {
          let videoInfo = res.data.detail
          this.videoDetail = videoInfo
          this.videoDetailCreatedAt = videoInfo.createdAt.slice(0, 10)
          this.playerOptions = {
            controlBar: {
              timeDivider: true, // 是否显示当前时间和持续时间的分隔符，"/"
              durationDisplay: false, // 是否显示持续时间
              remainingTimeDisplay: true, // 是否显示剩余时间
              fullscreenToggle: true, // 是否显示全屏按钮
            },
            muted: false, //是否静音播放
            language: 'zh-CN',
            autoplay: true,
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            poster: videoInfo.coverImg,
            sources: [
              {
                type: 'video/mp4',
                src: videoInfo.playUrl,
              },
            ],
            poster: videoInfo.coverImg,
          }
          this.artplayer = new Artplayer({
            container: '#artplayer',
            url: videoInfo.playUrl,
            playbackRate: true,
            setting: true, // 设置 （倍速）
            fullscreen: true, //全屏
            // fullscreenWeb: true, //网页全屏
            pip: true, //画中画
            autoplay: true, //自动播放
            poster: videoInfo.coverImg,
            theme: '#fff', //进度条颜色
          });
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onPlayerPlay(player) { },
    onPlayerPause(player) { },
    playerStateChanged(playerCurrentState) { },
  },
  //接口 一进入页面就执行的
  mounted() {
    this.getClassList()
  },
  //注册子组件
  components: { aplayer },
  computed: {},
  //过滤器
  filters: {
    filtime(val) { },
  },
}
</script>

<style lang="less" scoped>
@import './css/style.css';

.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.box {
  background: #eff4ff;
}

.video-box {
  width: 1200px;
  margin: 0 auto;
  min-height: 800px;
  padding-bottom: 72px;
}

.video-title {
  margin: 38px 0 24px;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
}

.video-header {
  display: flex;
  font-size: 16px;
  color: #9b9da2;
  margin: 14px auto;
  width: 80%;
  justify-content: space-evenly;
}

// 视屏样式
/deep/ #vjs_video_536 {
  height: auto !important;
  background: transparent;
}

/deep/ .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .video-js .vjs-tech {
  height: auto;
  position: static;
  height: 600px;
}

/deep/ .vjs-poster {
  background: transparent;
  background-size: cover;
}

/deep/.video-js {
  width: 80%;
  margin: 0 auto;
  height: 100%;
}

.tetailIntroduce {
  font-size: 16px;
  width: 956px;
  margin: 32px auto 0px;
  text-indent: 2em;
}

// 面包屑
.nav {
  padding-top: 60px;
}


/deep/ .video-player-box-audio .vjs-has-started .vjs-poster {
  display: block !important;
}

/deep/.art-video-player {
  width: 1065px !important;
  // height: 600px !important;
  height: 599px !important;
}

/deep/.video-player>img {
  border: 1px solid red;
  height: 100%;
}


.audioCover {
  width: 1065px;
  height: 600px;
  background-color: plum;
  z-index: 110;
}

.audioCover>img {
  width: 1065px;
  height: 600px;
}
</style>

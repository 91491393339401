<template>
  <div class="box">
    <div class="topImg">
      <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/top-img.png" alt="" />
      <div class="topImg-headline">
        <div class="topImg-title">产品-软件</div>
      </div>
    </div>
    <!-- 中心内容部分 -->
    <div class="center-content">
      <div class="center-one">
        <div class="bj-img">
          <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/xling-tong.png" alt="" />
        </div>
        <div class="bj">
          <p>
            心灵通是基于互联网思维搭建的心理健康咨询与教育一体化平台，通过平台整合行业专家、优质课程和特色服务，为学校搭建精准化心理测评、 全程化危机干预、多元化咨询指导和个性化心理教育四大工作平台，致力于解决教育管理部门和学校心理健康工作中部分存在的课程资源不足、
            筛查精度不高、咨询力量不够和干预过程管理不严的问题，通过闭环管理、过程留痕和数据驱动等手段建设有效降低危机概率，提高学生的心理健康素养。 心灵通包括心理测评、访谈管理、心理咨询和心理教育等9个子系统，可独立应用，也可整合起来应用。
          </p>
        </div>
      </div>
      <div class="center-two">
        <div>
          <div class="plan-titleBar" ref="plan-titleBar">九大系统</div>
          <div>
            <el-tabs :tab-position="tabPosition" v-model="tabsValue" style="margin-top: 70px">
              <el-tab-pane label="心理测评系统" name="0">
                <div style="margin-left: 60px">
                  <div class="center-two-title">心理测评系统</div>
                  <p class="center-two-text">
                    心理测评系统支持定向测试和集体测试两种模式，经过定制量表、发布测评、开展测评、过程监控和结果管理几个步骤完成测评，最终自动生成测评报告。
                    系统根据学校需求不同，提供了标准化量表库和个性化量表管理，学校可以选择标准量表，也可以导入个性化量表或者自定义量表，大大提高了学校开展心理普测的便捷性和准确率。 测评系统支持PC端和微信小程序，测评方便快捷。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-first.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="心理咨询系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">心理咨询系统</div>
                  <p class="center-two-text">
                    系统根据不同的咨询场景提供多种咨询服务形式，保障同学们的咨询体验，具体形式包括：针对简单的咨询问题，通过智能咨询和机器人的方式解答； 针对稍微复杂的常见问题，可以通过在线专家咨询的方式进行，支持实时和非实时两种模式；针对比较复杂的问题采用线下咨询的模式，支持咨询预约、
                    过程记录、咨询处理和自动归档等功能。系统还提供专家管理和案例管理功能。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-fourth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="课程教学系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">课程教学系统</div>
                  <p class="center-two-text">
                    课程教学系统主要解决学校的心理课程教学问题，涵盖多种教学模式，一种是线上教学模式，通过提供精品在线课程或者个性化定制校本课程，实现学生自助线上学习，
                    支持在线视频、作业、讨论和考试；一种是混合式教学模式，通过平台有效支撑教师进行教学改革，完成课前自主学习、课中讨论交互、课后复习强化的教学组织； 一种是线下教学模式，支持教师在课堂进行优质资源的分发讨论。系统还提供一些音视频资源，作为学生自助学习使用。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/courseTeaching.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="活动管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">活动管理系统</div>
                  <p class="center-two-text">活动管理是一个满足心理健康教育活动全流程管理的系统，具体包括活动发布、活动报名、过程管理和活动评价。活动形式具体包括主题教育、自主学习、团体咨询活动、沙龙等。</p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-seventh.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="访谈管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">访谈管理系统</div>
                  <p class="center-two-text">
                    测评产生的名单将进入访谈环节，由各院系通过访谈进一步确认学生的情况。访谈管理系统提供了任务分配、访谈管理、过程留痕等功能， 有效地将院系负责人、辅导员组织到访谈工作中来，做到分工明确、过程可查、结果可控。
                    访谈结果通过标签方式进一步分组，产生待咨询清单，进入咨询环节。访谈管理系统通过信息化流程的梳理打造实现了访谈名单的规范化跟进管理，并做到业务留痕。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-third.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="重点关注系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">重点关注系统</div>
                  <p class="center-two-text">
                    重点关注系统是一个日常工作系统，用于院系辅导员、心理委员在日常生活学习过程中上报一些异常情况， 比如行为异常、突发事件涉及到的学生，进入重点关注系统，重点关注分为校级关注和院级关注。
                    辅导员和心理委员要将重点关注学生的一些谈话记录、行为记录、重要事项、处理办法及时上传系统，做到一生一策，过程可控。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-second.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="干预管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">干预管理系统</div>
                  <p class="center-two-text">干预管理系统是对需要干预的学生进行全程管理的信息化支撑工具，系统提供了全流程管理功能，方便地实现干预清单的及时分发、任务管理和结果管理。</p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-eignth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="门户管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">门户管理系统</div>
                  <p class="center-two-text">
                    门户管理系统定位于展现学校心理健康咨询与教育工作的相关服务和成果，具体包括宣传心理健康教育相关政策，展示中心具体工作， 及时发布组织活动，普及心理健康教育知识。满足学校建设融思想性、知识性、趣味性、服务性于一体的心理健康教育网站的需求，
                    促进学生关注心理健康、助人自助、快乐成长，营造健康和谐、积极向上的校园氛围。门户管理系统包括栏目管理、内容管理等相关功能，支持多种框架，支持个性化定制。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-fifth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="档案管理系统">
                <div style="margin-left: 60px">
                  <div class="center-two-title">档案管理系统</div>
                  <p class="center-two-text">
                    通过全流程数据留痕，平台完整记录了学生在评测、课程、活动、咨询中的表现，完整记录了教师在平台进行教学、咨询和服务的完整表现，
                    这样就可以生成学生和教师画像，以及学生和教师成长档案，实现“一生一档案，一师一档案”。还可以基于运行数据，生成《大学生心理健康咨询与教育发展研究》等分析报告， 邀约专家给予诊断建议，为建设示范性大学生心理健康咨询中心提供指导建议。
                  </p>
                  <div class="center-two-img">
                    <img src="https://xlingtong.oss-cn-beijing.aliyuncs.com/x-lingtong/product/center-two-sixth.png" alt="" />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: 'left',
      //   tabsValue: ''
    }
  },
  mounted() {
    // console.log(this.$route)
    let tabIndex = this.$route.params.index
    if (tabIndex) {
      // 改变当前选中
      this.tabsValue = tabIndex
      // console.log(this.$refs['plan-titleBar'])
      this.$nextTick(() => {
        this.$refs['plan-titleBar'].scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    }
  },
  computed: {
    tabsValue: {
      get() {
        return this.$store.state.productSoftActive + ''
      },
      set(newval) {
        this.$store.state.productSoftActive = newval
      },
    },
  },
}
</script>

<style lang="less" scoped>
@import '../css/global.css';
.topImg-headline {
  width: 1200px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.topImg-title {
  line-height: 108px;
}
// 中心内容
.center-content {
  width: 1200px;
  margin: 0 auto;
  //   height: 2000px;
  height: 1600px;
  background: #ffffff;
}
.center-one {
  padding-top: 60px;
  position: relative;
}
.bj {
  background-color: #075bff;
  width: 1067px;
  height: 348px;
  position: absolute;
  top: 60px;
  left: 130px;
  z-index: 0;
}
.bj > p {
  width: 482px;
  height: 224px;
  float: right;
  padding: 50px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.bj-img {
  margin-top: 36px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.bj-img > img {
  width: 618px;
}

.plan-titleBar {
  height: 56px;
  font-size: 32px;
  font-weight: 600;
  color: #3b3d41;
  line-height: 56px;
  margin: 0 auto;
  padding-bottom: 14px;
  padding-top: 74px;
  display: flex;
  letter-spacing: 6px;
  justify-content: space-around;
}
.center-two-title {
  height: 40px;
  line-height: 34px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3b3d41;
  letter-spacing: 2px;
}
.center-two-text {
  font-size: 16px;
  line-height: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3b3d41;
  margin: 50px 0px;
}
.center-two-img > img {
  width: 976px;
}

/deep/.el-tabs__item {
  height: 90px;
  line-height: 90px;
}
/deep/.el-tabs__active-bar {
  background-color: #0156ff;
  height: 50px !important;
  margin-top: 0px;
}
/deep/.el-tabs__item.is-active {
  color: #0156ff;
}
/deep/.el-tabs__item:hover {
  color: #0156ff;
  cursor: pointer;
}
/deep/.el-tabs__item {
  height: 75px;
  line-height: 40px;
}
</style>